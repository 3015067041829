// Section.tsx
import React from "react";

interface SectionProps {
  title: string;
  children: React.ReactNode;
  hide?: boolean;
}

const Section: React.FC<SectionProps> = ({ title, children, hide }) => {
  return hide ? null : (
    <div className='text-sm h-full overflow-y-auto bg-white p-4 rounded-md shadow-md border border-gray-200'>
      <div className='font-bold'>{title}</div>
      <div className='h-[1px] w-full bg-zinc-300 mt-2 mb-4' />
      <div className='p-1'>{children}</div>
    </div>
  );
};

export default Section;
