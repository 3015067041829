import React, { useRef, useState } from "react";
import { LuChevronRight } from "react-icons/lu";

interface MenuItem {
  id: string;
  title: string;
  icon: JSX.Element;
  children: { id: string; title: string }[];
}

interface VerticalMenuProps {
  menuItems: MenuItem[];
  selectedMenu: String;
  onClick: (parentId: string, childId: string) => void;
}

const VerticalMenu: React.FC<VerticalMenuProps> = ({
  selectedMenu,
  menuItems,
  onClick,
}) => {
  const [openMenus, setOpenMenus] = useState<Record<string, boolean>>(() =>
    menuItems.reduce((acc, menu) => ({ ...acc, [menu.id]: true }), {}),
  );

  const toggleMenu = (id: string) => {
    setOpenMenus((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const calculateHeight = (ref: React.RefObject<HTMLUListElement>) => {
    return ref.current ? `${ref.current.scrollHeight}px` : "0px";
  };

  return (
    <div className='w-64 text-sm font-medium bg-white shadow-md rounded-md border border-gray-200 overflow-y-auto'>
      <ul className='flex flex-col space-y-0.5 p-4'>
        {menuItems.map((menu) => {
          const submenuRef = useRef<HTMLUListElement>(null);

          return (
            <li key={menu.id}>
              {/* Parent Menu */}
              <button
                onClick={() => toggleMenu(menu.id)}
                className='flex items-center justify-between w-full p-2 transition'
              >
                <span className='flex items-center space-x-2'>
                  <span>{menu.icon}</span>
                  <span>{menu.title}</span>
                </span>
                <span
                  className={`transform transition-transform duration-300 ${
                    openMenus[menu.id] ? "rotate-90" : "rotate-0"
                  }`}
                >
                  <LuChevronRight size={16} />
                </span>
              </button>

              {/* Submenu */}
              <ul
                ref={submenuRef}
                className='overflow-hidden transition-all duration-300 ease-in-out'
                style={{
                  height: openMenus[menu.id]
                    ? calculateHeight(submenuRef)
                    : "0px",
                }}
              >
                {menu.children.map((child) => (
                  <li key={child.id}>
                    <button
                      onClick={() => onClick(menu.id, child.id)}
                      className={`w-full text-left p-2 pl-8 rounded ${
                        selectedMenu === `${menu.id}-${child.id}`
                          ? "text-primary-blue-100 font-bold bg-sky-100"
                          : "hover:bg-sky-50"
                      }`}
                    >
                      {child.title}
                    </button>
                  </li>
                ))}
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default VerticalMenu;
