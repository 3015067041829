import { FaWindows } from "react-icons/fa";
import { FcIphone, FcPhoneAndroid, FcTabletAndroid } from "react-icons/fc";
import { IoLogoAndroid } from "react-icons/io";
import { SiIos, SiMacos } from "react-icons/si";
import { DeviceMetadata } from "../__generated__/graphql";

export const getDeviceIcon = (device: DeviceMetadata) => {
  if (device.isDesktop) {
    return device.device?.brand === "Apple" ? <SiMacos /> : <FaWindows />;
  }
  if (device.isMobile) {
    if (device.device?.brand === "Apple") {
      return device.device?.type === "iPhone" ? <FcIphone /> : <SiIos />;
    }
    if (device.device?.brand === "Android") {
      return device.device?.type === "Tablet" ? (
        <FcTabletAndroid />
      ) : (
        <FcPhoneAndroid />
      );
    }
  }
  return <IoLogoAndroid />;
};
