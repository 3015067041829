import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  SiteSettingsType,
  useGetSiteSettingsQuery,
  useUpdateSiteSettingsMutation,
} from "../../__generated__/graphql";
import client from "../../apolloClient";
import { useCompany } from "../../contexts/CompanyContext";
import { useUnsavedChanges } from "../../contexts/UnsavedChangesContext";
import ActionBanner from "../ActionBanner";
import LoadingAnimation from "../LoadingAnimation";
import CodeBlock from "./CodeBlock";
import Section from "./Section";
import { SettingsComponentProps } from "../../utils/interfaces";

const Configuration: React.FC<SettingsComponentProps> = ({ section }) => {
  const { selectedCompany } = useCompany(); // Use the useCompany hook to get the selected company
  const { data, loading, error, refetch } = useGetSiteSettingsQuery({
    client,
    variables: {
      companyId: selectedCompany
        ? parseInt(selectedCompany.value, 10)
        : undefined,
    },
  });

  const [settings, setSettings] = useState<SiteSettingsType>({
    apiKey: "",
    backendApi: "",
    sailfishBackendApi: "",
    defaultBackendApiUrl: "",
    defaultSailfishBackendApiUrl: "",
    isDefaultBackendApiUrl: false,
    isDefaultSailfishBackendApiUrl: false,
  });
  const originalSettingsRef = useRef<SiteSettingsType>(settings);
  const [urlError, setUrlError] = useState<string | null>(null);

  const [updateSiteSettings] = useUpdateSiteSettingsMutation({
    client,
  });
  const {
    isDirty,
    setExternalSaveFunction,
    markSettingAsChanged,
    clearUnsavedChanges,
  } = useUnsavedChanges();

  useEffect(() => {
    if (data && data.siteSettings) {
      setSettings(data.siteSettings);
      originalSettingsRef.current = data.siteSettings;
    }
  }, [data]);

  // Refetch the query whenever the selectedCompany changes
  useEffect(() => {
    if (selectedCompany) {
      refetch({
        companyId: parseInt(selectedCompany.value, 10),
      });
    } else {
      refetch({
        companyId: undefined,
      });
    }
  }, [selectedCompany, refetch]);

  const handleInputChange = useCallback(
    (field: keyof SiteSettingsType, value: any) => {
      setSettings((prevSettings) => {
        const newSettings = { ...prevSettings, [field]: value };
        if (prevSettings[field] !== value) {
          markSettingAsChanged("configuration");
        }
        return newSettings;
      });
    },
    [markSettingAsChanged],
  );

  const validateUrlOnChange =
    (field: keyof SiteSettingsType) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newUrl = e.target.value;
      handleInputChange(field, newUrl);

      try {
        const parsedUrl = new URL(newUrl); // Validate the URL
        if (parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:") {
          setUrlError(null); // Valid URL
        } else {
          setUrlError("Please enter a valid URL with http or https.");
        }
      } catch {
        setUrlError("Please enter a valid URL.");
      }
    };

  const handleSaveChanges = useCallback(async () => {
    if (isDirty("configuration")) {
      try {
        const input = {
          backendApi: settings.backendApi,
          sailfishBackendApi: settings.sailfishBackendApi,
        };

        await updateSiteSettings({
          variables: {
            input, // Always provide the required input
            companyId: selectedCompany
              ? parseInt(selectedCompany.value, 10)
              : null, // Company ID is optional
          },
        });

        originalSettingsRef.current = settings;
        clearUnsavedChanges("configuration");
      } catch (error) {
        console.error("Error updating site settings:", error);
      }
    }
  }, [
    isDirty,
    settings,
    clearUnsavedChanges,
    updateSiteSettings,
    selectedCompany,
  ]);

  useEffect(() => {
    setExternalSaveFunction("configuration", handleSaveChanges);
  }, [handleSaveChanges, setExternalSaveFunction]);

  const handleDiscardChanges = useCallback(() => {
    setSettings(originalSettingsRef.current);
    clearUnsavedChanges("configuration");
  }, [clearUnsavedChanges]);

  useEffect(() => {
    if (isDirty("configuration")) {
      handleDiscardChanges();
    }
  }, [section]);

  return (
    <>
      {loading ? (
        <LoadingAnimation text={"Loading..."} />
      ) : error ? (
        <p>Error: {error.message}</p>
      ) : (
        <>
          <Section title='Sailfish Configuration' hide={section !== "sailfish"}>
            <CodeBlock title='API Key' code={settings.apiKey} />
          </Section>
          <Section title='Site Configuration' hide={section !== "site"}>
            <TextField
              disabled
              label='Sailfish Backend API URL'
              variant='outlined'
              value={settings.sailfishBackendApi}
              onChange={validateUrlOnChange("sailfishBackendApi")}
              fullWidth
              margin='normal'
              error={!!urlError}
              helperText={
                urlError
                  ? urlError
                  : "If you change this, you will need to add a CNAME record, Nginx Proxy, or similar that reroutes our websockets."
              }
              inputProps={{
                className: "input input-bordered",
              }}
            />
            <TextField
              disabled
              label='Backend API URL'
              variant='outlined'
              value={settings.backendApi}
              onChange={validateUrlOnChange("backendApi")}
              fullWidth
              margin='normal'
              error={!!urlError}
              inputProps={{
                className: "input input-bordered",
              }}
            />
            {!settings.isDefaultBackendApiUrl ||
              (!settings.isDefaultSailfishBackendApiUrl && (
                <Section title='Mandatory CNAME Records'>
                  {" "}
                  <TableContainer component={Paper}>
                    <Table aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Value</TableCell>
                          <TableCell>TTL</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!settings.isDefaultBackendApiUrl && (
                          <TableRow>
                            <TableCell>{settings.backendApi}</TableCell>
                            <TableCell>CNAME</TableCell>
                            <TableCell>
                              {settings.defaultBackendApiUrl}
                            </TableCell>
                            <TableCell>5-300</TableCell>
                          </TableRow>
                        )}
                        {!settings.isDefaultSailfishBackendApiUrl && (
                          <TableRow>
                            <TableCell>{settings.sailfishBackendApi}</TableCell>
                            <TableCell>CNAME</TableCell>
                            <TableCell>
                              {settings.defaultSailfishBackendApiUrl}
                            </TableCell>
                            <TableCell>5-300</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Section>
              ))}
          </Section>
          {isDirty("configuration") && (
            <ActionBanner
              onSave={() => handleSaveChanges()}
              onDiscard={() => handleDiscardChanges()}
              onUndo={() => {}}
              onRedo={() => {}}
              canUndo={false}
              canRedo={false}
            />
          )}
        </>
      )}
    </>
  );
};

export default Configuration;
