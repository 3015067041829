import { List, ListItem, ListItemText } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ExceptionRecordType } from "../../__generated__/graphql";
import Pagination from "../Pagination";

interface ExceptionsListProps {
  exceptions: Array<ExceptionRecordType>;
  onSelect: (exception: ExceptionRecordType) => void;
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  loading: boolean;
}

const ExceptionsList: React.FC<ExceptionsListProps> = ({
  exceptions,
  onSelect,
  page,
  totalPages,
  onPageChange,
  loading,
}) => {
  const [inputPage, setInputPage] = useState<string>(page.toString());
  const [warningMessage, setWarningMessage] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const pageEntryWidth = 20 + 10 * totalPages.toString().length;

  useEffect(() => {
    if (warningMessage) {
      const timer = setTimeout(() => {
        setWarningMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [warningMessage]);

  const handlePageInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setInputPage(event.target.value);
  };

  const handlePageInputSubmit = () => {
    let newPage = parseInt(inputPage, 10);
    if (isNaN(newPage) || newPage <= 0) {
      newPage = 1;
      setWarningMessage("Entered page is less than 1. Setting to page 1.");
    } else if (newPage > totalPages) {
      newPage = totalPages;
      setWarningMessage(
        `Entered page is greater than the total pages. Setting to page ${totalPages}.`,
      );
    }
    setInputPage(newPage.toString());
    onPageChange(newPage);
  };

  useEffect(() => {
    setInputPage(page.toString());
  }, [page]);

  const handlePageInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Enter") {
      handlePageInputSubmit();
    }
  };

  const handleExceptionSelect = (exception: ExceptionRecordType) => {
    onSelect(exception);
    searchParams.set("exceptionId", exception.id); // Update the URL with the selected exception ID
    setSearchParams(searchParams);
  };

  return (
    <div className='flex flex-col h-full'>
      <div className='flex-1 overflow-auto'>
        {loading ? (
          <div>Loading exceptions...</div>
        ) : (
          <List>
            {exceptions.map((exception) => (
              <ListItem
                button
                key={exception.id}
                onClick={() => handleExceptionSelect(exception)}
              >
                <ListItemText
                  primary={`Exception ${exception.id}`}
                  primaryTypographyProps={{ fontSize: 14 }}
                  secondary={`${new Date(
                    parseInt(exception.timestampMs),
                  ).toLocaleString()}`}
                  secondaryTypographyProps={{ fontSize: 12 }}
                />
              </ListItem>
            ))}
          </List>
        )}
      </div>
      <div className='bg-white'>
        <Pagination
          page={page}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
};

export default ExceptionsList;
