import { useQuery } from "@apollo/client";
import TextField from "@mui/material/TextField";
import { useCallback, useEffect, useRef, useState } from "react";
import client from "../../apolloClient";
import { useCompany } from "../../contexts/CompanyContext";
import { useUnsavedChanges } from "../../contexts/UnsavedChangesContext";
import {
  GET_CAPTURE_SETTINGS,
  UPDATE_CAPTURE_SETTINGS,
} from "../../graphql/settingsQueries";
import ActionBanner from "../ActionBanner";
import Checkbox from "../Checkbox";
import LoadingAnimation from "../LoadingAnimation";
import Section from "./Section";
import TagInputField from "../TagInputField";
import { SettingsComponentProps } from "../../utils/interfaces";

// Define the type of the settings state
interface Settings {
  recordCanvas: boolean;
  recordCrossOriginIframes: boolean;
  collectFonts: boolean;
  inlineImages: boolean;
  sampling: any; // Adjust the type as necessary
  canEdit?: boolean; // Make canEdit optional
  ignoreDomains: string[];
  cssFromDomain: string;
  cssToDomain: string;
}

const Recording: React.FC<SettingsComponentProps> = ({ section }) => {
  const { selectedCompany } = useCompany(); // Use the useCompany hook to get the selected company
  const { data, loading, error, refetch } = useQuery(GET_CAPTURE_SETTINGS, {
    client,
    variables: {
      companyId: selectedCompany
        ? parseInt(selectedCompany.value, 10)
        : undefined,
    },
  });
  const [settings, setSettings] = useState<Settings>({
    recordCanvas: false,
    recordCrossOriginIframes: false,
    collectFonts: false,
    inlineImages: false,
    sampling: {},
    canEdit: false,
    ignoreDomains: [],
    cssFromDomain: "",
    cssToDomain: "",
  });

  const originalSettingsRef = useRef<Settings>(settings);

  const {
    isDirty,
    setExternalSaveFunction,
    markSettingAsChanged,
    clearUnsavedChanges,
  } = useUnsavedChanges();

  useEffect(() => {
    if (data && data.captureSettings) {
      setSettings(data.captureSettings);
      originalSettingsRef.current = data.captureSettings;
    }
  }, [data]);

  const cssDomainReplaceError =
    !!settings.cssFromDomain !== !!settings.cssToDomain;

  // Refetch the query whenever the selectedCompany changes
  useEffect(() => {
    if (selectedCompany) {
      refetch({
        companyId: parseInt(selectedCompany.value, 10),
      });
    } else {
      refetch({
        companyId: undefined,
      });
    }
  }, [selectedCompany, refetch]);

  const handleChange = (field: keyof Settings) => (value: boolean | string) => {
    setSettings((prevSettings) => {
      const newSettings = { ...prevSettings, [field]: value };
      if (prevSettings[field] !== value) {
        markSettingAsChanged("recording");
      }
      return newSettings;
    });
  };

  const handleIgnoreDomainsChange = (value: string[]) => {
    setSettings((prevSettings) => {
      const newSettings = { ...prevSettings, ignoreDomains: value };
      if (!isDirty("recording")) {
        markSettingAsChanged("recording");
      }
      return newSettings;
    });
  };

  const handleSaveChanges = useCallback(async () => {
    if (isDirty("recording") && !cssDomainReplaceError) {
      try {
        await client.mutate({
          mutation: UPDATE_CAPTURE_SETTINGS,
          variables: {
            input: {
              recordCanvas: settings.recordCanvas,
              recordCrossOriginIframes: settings.recordCrossOriginIframes,
              collectFonts: settings.collectFonts,
              inlineImages: settings.inlineImages,
              sampling: JSON.stringify(settings.sampling),
              ignoreDomains: settings.ignoreDomains,
              cssFromDomain: settings.cssFromDomain,
              cssToDomain: settings.cssToDomain,
            },
          },
        });
        originalSettingsRef.current = settings;
        clearUnsavedChanges("recording");
      } catch (error) {
        console.error("Error updating capture settings:", error);
      }
    }
  }, [isDirty, settings, clearUnsavedChanges]);

  useEffect(() => {
    setExternalSaveFunction("recording", handleSaveChanges);
  }, [handleSaveChanges, setExternalSaveFunction]);

  const handleDiscardChanges = useCallback(() => {
    setSettings(originalSettingsRef.current);
    clearUnsavedChanges("recording");
  }, [clearUnsavedChanges]);

  useEffect(() => {
    if (isDirty("recording")) {
      handleDiscardChanges();
    }
  }, [section]);

  const disabledTitle =
    "You need Admin or CompanyAdmin privileges to edit these settings.";

  return (
    <>
      {loading ? (
        <LoadingAnimation text={"Loading..."} />
      ) : error ? (
        <p>Error: {error.message}</p>
      ) : (
        <>
          <Section title='General' hide={section !== "general"}>
            <div className='space-y-4'>
              <Checkbox
                label='Record Canvas'
                checked={settings.recordCanvas}
                onChange={handleChange("recordCanvas")}
                disabled={!settings.canEdit}
                tooltip={settings.canEdit ? "" : disabledTitle}
              />
              <Checkbox
                label='Record Cross-Origin IFrames'
                checked={settings.recordCrossOriginIframes}
                onChange={handleChange("recordCrossOriginIframes")}
                disabled={!settings.canEdit}
                tooltip={settings.canEdit ? "" : disabledTitle}
              />
              <Checkbox
                label='Collect Fonts'
                checked={settings.collectFonts}
                onChange={handleChange("collectFonts")}
                disabled={!settings.canEdit}
                tooltip={settings.canEdit ? "" : disabledTitle}
              />
              <Checkbox
                label='Inline Images'
                checked={settings.inlineImages}
                onChange={handleChange("inlineImages")}
                disabled={!settings.canEdit}
                tooltip={settings.canEdit ? "" : disabledTitle}
              />
              <TagInputField
                title='Ignore domains'
                tags={settings.ignoreDomains}
                setTags={handleIgnoreDomainsChange}
              />
              <div className='flex gap-4'>
                <TextField
                  type='url'
                  label='Replace Domain for CSS from'
                  value={settings.cssFromDomain}
                  onChange={(e) =>
                    handleChange("cssFromDomain")(e.target.value)
                  }
                  error={cssDomainReplaceError && !settings.cssFromDomain}
                  helperText={
                    cssDomainReplaceError &&
                    !settings.cssFromDomain &&
                    "Both replace from and replace to fields are required"
                  }
                  fullWidth
                  variant='outlined'
                />
                <TextField
                  type='url'
                  label='Replace Domain for CSS to'
                  value={settings.cssToDomain}
                  onChange={(e) => handleChange("cssToDomain")(e.target.value)}
                  error={cssDomainReplaceError && !settings.cssToDomain}
                  helperText={
                    cssDomainReplaceError &&
                    !settings.cssToDomain &&
                    "Both css are required"
                  }
                  fullWidth
                  variant='outlined'
                />
              </div>
            </div>
          </Section>
          <Section title='Privacy' hide={section !== "privacy"}>
            <p className='mb-2 italic'>
              The following data is anonymized unless you have checked the box
              to record the actual data. Some fields are unable to be selected,
              which are grayed-out.
            </p>
            <div className='space-y-4'>
              <Section title='Secret Data'>
                <Checkbox
                  label="Record user's Password"
                  checked={false}
                  onChange={() => {
                    console.error(
                      "User tried to enable recording of passwords",
                    );
                  }}
                  disabled={true}
                  tooltip={"Users cannot enable the recording of passwords"}
                />
              </Section>
              <Section title='Personally Identifiable Information (PII) Data'>
                <div className='space-y-2'>
                  <Checkbox
                    label="Record user's real name"
                    checked={true}
                    onChange={() => {}} // TODO - implement
                    disabled={!settings.canEdit}
                    tooltip={settings.canEdit ? "" : disabledTitle}
                  />
                  <Checkbox
                    label="Record user's credit card information"
                    checked={false}
                    onChange={() => {
                      console.error(
                        "User tried to enable recording of credit card information",
                      );
                    }}
                    disabled={true}
                    tooltip={
                      "Users cannot enable the recording of credit card information"
                    }
                  />
                  <Checkbox
                    label="Record user's Social Security Number (SSN)"
                    checked={false}
                    onChange={() => {
                      console.error(
                        "User tried to enable recording of SSN data",
                      );
                    }}
                    disabled={true}
                    tooltip={"Users cannot enable the recording of SSN data"}
                  />
                  <Checkbox
                    label="Record user's Date of Birth (DOB)"
                    checked={false}
                    onChange={() => {
                      console.error(
                        "User tried to enable recording of DOB data",
                      );
                    }}
                    disabled={true}
                    tooltip={"Users cannot enable the recording of DOB data"}
                  />
                </div>
              </Section>
              <Section title='Additional Elements'>
                <p>
                  Sailfish can further anonymize data upon ingestion, giving
                  engineers more control over what we store.&nbsp; E.g. you may
                  want to not store data such as instant messages between
                  co-workers. In that case, do the following:
                </p>
                <br />
                <p style={{ textIndent: "2em" }}>
                  Add &nbsp;
                  <b>
                    <code>sailfishSanitize</code>
                  </b>
                  &nbsp; to the <code>className</code> of the element you want
                  to sanitize:&nbsp;&nbsp;
                  <code>
                    <b>&lt;... className='... sailfishSanitize' ...&gt;</b>
                  </code>
                </p>
              </Section>
            </div>
          </Section>
          {isDirty("recording") && (
            <ActionBanner
              onSave={() => handleSaveChanges()}
              onDiscard={() => handleDiscardChanges()}
              onUndo={() => {}}
              onRedo={() => {}}
              canUndo={false}
              canRedo={false}
            />
          )}
        </>
      )}
    </>
  );
};

export default Recording;
