import React from "react";
import Section from "./Section";
import { SettingsComponentProps } from "../../utils/interfaces";

const SetupInstall: React.FC<SettingsComponentProps> = ({ section }) => {
  return (
    <>
      {/* Static Frontend Section */}
      {
        <Section
          title='Static Frontend/Unmanaged Sites'
          hide={section !== "static"}
        >
          <p>
            For static HTML sites or platforms like Shopify, refer to this
            documentation:
          </p>
          <a
            href='https://docs.sailfishqa.com/docs/getting-started/front-end-configuration/'
            target='_blank'
            rel='noopener noreferrer'
            className='text-blue-500 underline'
          >
            Frontend Configuration Documentation
          </a>
        </Section>
      }

      {/* Frontend Section */}
      <Section title='Frontend' hide={section !== "fe"}>
        <h6 className='text-lg font-semibold mb-2'>
          JS/TS (React, Angular, etc)
        </h6>
        <p>
          For modern frontend frameworks, follow this guide for integration:
        </p>
        <a
          href='https://docs.sailfishqa.com/docs/getting-started/front-end-configuration/'
          target='_blank'
          rel='noopener noreferrer'
          className='text-blue-500 underline'
        >
          Frontend Configuration Documentation
        </a>
      </Section>

      {/* Backend & Server-Side Scripts Section */}
      <Section title='Backend & Server-Side Scripts' hide={section !== "be"}>
        <div className='mb-6'>
          <h6 className='text-lg font-semibold mb-2'>Python</h6>
          <p>Configure Python backend integration using the following guide:</p>
          <a
            href='https://docs.sailfishqa.com/docs/getting-started/back-end-configuration/python'
            target='_blank'
            rel='noopener noreferrer'
            className='text-blue-500 underline'
          >
            Python Configuration Documentation
          </a>
        </div>

        <div className='mb-6'>
          <h6 className='text-lg font-semibold mb-2'>JS/TS</h6>
          <ul className='list-disc list-inside'>
            <li className='mb-2'>
              <strong>Node.js:</strong>{" "}
              <a
                href='https://docs.sailfishqa.com/docs/getting-started/back-end-configuration/node-js-ts'
                target='_blank'
                rel='noopener noreferrer'
                className='text-blue-500 underline'
              >
                Node.js/TypeScript Configuration
              </a>
            </li>
            <li>
              <strong>Next.js:</strong>{" "}
              <a
                href='https://docs.sailfishqa.com/docs/getting-started/back-end-configuration/next-js-ts'
                target='_blank'
                rel='noopener noreferrer'
                className='text-blue-500 underline'
              >
                Next.js/TypeScript Configuration
              </a>
            </li>
          </ul>
        </div>

        <div className='mb-6'>
          <h6 className='text-lg font-semibold mb-2'>PHP</h6>
          <ul className='list-disc list-inside'>
            <li>
              <strong>Laravel:</strong>{" "}
              <a
                href='https://docs.sailfishqa.com/docs/getting-started/back-end-configuration/laravel-php'
                target='_blank'
                rel='noopener noreferrer'
                className='text-blue-500 underline'
              >
                Laravel Configuration Documentation
              </a>
            </li>
          </ul>
        </div>
      </Section>
    </>
  );
};

export default SetupInstall;
