import { useState } from "react";
import { useAuth } from "../contexts/AuthContext";

function ContactSupportLink() {
  const { user } = useAuth(); // Move this inside the component
  const [showFallback, setShowFallback] = useState(false);

  const handleMailtoClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const emailClientIsUnavailable = !navigator?.userAgent.includes("Mail");
    if (emailClientIsUnavailable) {
      e.preventDefault(); // Prevent the default mailto behavior.
      setShowFallback(true); // Show the fallback popup.
    }
  };

  return (
    <div className='text-xs leading-5 font-bold'>
      Need more help?{" "}
      <a
        href={`mailto:support@sailfish.cloud?subject=Product%20Support%20Request&body=${encodeURIComponent(
          `Hey Sailfish Support Team,

It's ${
            user?.name || "Your Name"
          } - I'm having an issue with your product. I'm unable to do the following:

insertIssueHere

Thanks again,
${user?.name || "Your Name"}`,
        )}`}
        className='text-blue-500 underline'
        onClick={handleMailtoClick}
      >
        Contact your Customer support
      </a>
      {/* Fallback Popup */}
      {showFallback && (
        <div className='mt-2 p-4 border rounded bg-gray-100'>
          <p>
            It seems your email client is not configured. You can copy the email
            address below and contact us directly:
          </p>
          <p className='mt-2 text-blue-500 font-bold'>support@sailfish.cloud</p>
          <button
            className='mt-2 px-4 py-2 bg-blue-500 text-white rounded'
            onClick={() => setShowFallback(false)}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
}

export default ContactSupportLink;
