import React, { createContext, useCallback, useContext, useState } from "react";
import { SettingsTabNames } from "../utils/interfaces";

interface UnsavedChangesContextProps {
  isDirty: (tab: SettingsTabNames) => boolean;
  markSettingAsChanged: (tab: SettingsTabNames) => void;
  clearUnsavedChanges: (tab: SettingsTabNames) => void;
  setExternalSaveFunction: (
    tab: SettingsTabNames,
    saveFn: () => Promise<void>,
  ) => void;
  externalSave: (tab: SettingsTabNames) => Promise<void>;
}

const UnsavedChangesContext = createContext<UnsavedChangesContextProps | null>(
  null,
);

export const UnsavedChangesProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [dirtyTabs, setDirtyTabs] = useState<Record<SettingsTabNames, boolean>>(
    {
      setup: false,
      recording: false,
      configuration: false,
      notifications: false,
      integrations: false,
    },
  );

  const [saveFunctions, setSaveFunctions] = useState<
    Record<SettingsTabNames, () => Promise<void>>
  >({
    setup: async () => {},
    recording: async () => {},
    configuration: async () => {},
    notifications: async () => {},
    integrations: async () => {},
  });

  const isDirty = useCallback(
    (tab: SettingsTabNames) => dirtyTabs[tab],
    [dirtyTabs],
  );

  const markSettingAsChanged = useCallback((tab: SettingsTabNames) => {
    setDirtyTabs((prev) => ({ ...prev, [tab]: true }));
  }, []);

  const clearUnsavedChanges = useCallback((tab: SettingsTabNames) => {
    setDirtyTabs((prev) => ({ ...prev, [tab]: false }));
  }, []);

  const setExternalSaveFunction = useCallback(
    (tab: SettingsTabNames, saveFn: () => Promise<void>) => {
      setSaveFunctions((prev) => ({ ...prev, [tab]: saveFn }));
    },
    [],
  );

  const externalSave = useCallback(
    async (tab: SettingsTabNames) => {
      await saveFunctions[tab]();
      clearUnsavedChanges(tab);
    },
    [saveFunctions, clearUnsavedChanges],
  );

  return (
    <UnsavedChangesContext.Provider
      value={{
        isDirty,
        markSettingAsChanged,
        clearUnsavedChanges,
        setExternalSaveFunction,
        externalSave,
      }}
    >
      {children}
    </UnsavedChangesContext.Provider>
  );
};

export const useUnsavedChanges = () => {
  const context = useContext(UnsavedChangesContext);
  if (!context) {
    throw new Error(
      "useUnsavedChanges must be used within an UnsavedChangesProvider",
    );
  }
  return context;
};
