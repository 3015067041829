import { formatDistanceToNow, parseISO } from "date-fns";

/**
 * Formats an ISO date string into a human-readable "time ago" format.
 * Falls back to the original value and logs an error if parsing fails.
 * @param isoTime - ISO 8601 date string (e.g., "2024-08-27T01:10:34.396179+00:00").
 * @returns A formatted string indicating the relative time (e.g., "3 days ago"),
 *          or the original value if there's an error.
 */
const formatRelativeTime = (isoTime: string): string => {
  try {
    // Parse the ISO string to a Date object
    const date = parseISO(isoTime);

    // Format the date as a relative time (e.g., "3 days ago")
    return formatDistanceToNow(date, { addSuffix: true });
  } catch (error) {
    // Log the error to the console
    console.error("Error formatting relative time:", error, { isoTime });

    // Return the original value as a fallback
    return isoTime;
  }
};

export default formatRelativeTime;
