import { Autocomplete, Switch } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  EditEngineeringTicketPlatformIntegrationInput,
  useGetEngineeringTicketPlatformIntegrationConfigsQuery,
  useGetEngineeringTicketPlatformIntegrationQuery,
  useUpdateEngineeringTicketPlatformIntegrationMutation,
} from "../../__generated__/graphql";
import { useCompany } from "../../contexts/CompanyContext";
import { useUnsavedChanges } from "../../contexts/UnsavedChangesContext";
import {
  APP_ROOT_DOMAIN,
  INTEGRATIONS,
  LINEAR_PRIORITIES,
} from "../../utils/constants";
import ActionBanner from "../ActionBanner";
import IntegrationButton from "../IntegrationButton";
import LoadingAnimation from "../LoadingAnimation";
import Section from "./Section";
import LinearLogo from "../../images/linearLogo.png";
import { AutocompleteOption } from "../../utils/interfaces";

const { LINEAR, LINEAR_OAUTH_URI } = INTEGRATIONS;

type IntegrationSettings = Omit<
  EditEngineeringTicketPlatformIntegrationInput,
  "provider"
>;

const TAB_NAME = "integrations";

const Integrations = () => {
  const { selectedCompany } = useCompany(); // Use the useCompany hook to get the selected company
  const { data, loading, error, refetch } =
    useGetEngineeringTicketPlatformIntegrationQuery({
      variables: {
        provider: LINEAR,
        companyId: selectedCompany
          ? parseInt(selectedCompany.value, 10)
          : undefined,
      },
    });

  const { data: integrationConfigsData } =
    useGetEngineeringTicketPlatformIntegrationConfigsQuery({
      variables: {
        companyId: selectedCompany
          ? parseInt(selectedCompany.value, 10)
          : undefined,
      },
    });

  const [settings, setSettings] = useState<IntegrationSettings>({
    pushAutoIdentifiedIssues: false,
    defaultPriority: 0,
    defaultProject: "",
    defaultTeam: "",
  });
  const originalSettingsRef = useRef<IntegrationSettings>(settings);

  const [updateIntegrations] =
    useUpdateEngineeringTicketPlatformIntegrationMutation();
  const {
    isDirty,
    setExternalSaveFunction,
    markSettingAsChanged,
    clearUnsavedChanges,
  } = useUnsavedChanges();

  const linearProjects: AutocompleteOption[] =
    data?.getEngineeringTicketPlatformIntegration?.projects || [];
  const linearTeams: AutocompleteOption[] =
    data?.getEngineeringTicketPlatformIntegration?.teams || [];
  const linearInstalled =
    data?.getEngineeringTicketPlatformIntegration?.installed;
  const linearClientId =
    integrationConfigsData?.getEngineeringTicketPlatformIntegrationConfigs
      ?.clientId;

  useEffect(() => {
    if (data?.getEngineeringTicketPlatformIntegration) {
      const {
        installed,
        pushAutoIdentifiedIssues,
        defaultPriority,
        defaultProject,
        defaultTeam,
      } = data.getEngineeringTicketPlatformIntegration;
      if (!installed) {
        return;
      }

      const linearSettings = {
        pushAutoIdentifiedIssues,
        defaultPriority,
        defaultProject,
        defaultTeam,
      };
      setSettings(linearSettings);
      originalSettingsRef.current = linearSettings;
    }
  }, [data]);

  // Refetch the query whenever the selectedCompany changes
  useEffect(() => {
    refetch({
      companyId: selectedCompany
        ? parseInt(selectedCompany.value, 10)
        : undefined,
    });
  }, [selectedCompany, refetch]);

  const handleInputChange = useCallback(
    (field: keyof IntegrationSettings, value: any) => {
      setSettings((prevSettings) => {
        const newSettings = { ...prevSettings, [field]: value };
        if (prevSettings[field] !== value) {
          markSettingAsChanged(TAB_NAME);
        }
        return newSettings;
      });
    },
    [markSettingAsChanged],
  );

  const handleSaveChanges = useCallback(async () => {
    if (isDirty(TAB_NAME)) {
      try {
        const input = {
          provider: LINEAR,
          ...settings,
        };

        await updateIntegrations({
          variables: {
            input, // Always provide the required input
            companyId: selectedCompany
              ? parseInt(selectedCompany.value, 10)
              : undefined, // Company ID is optional
          },
        });

        originalSettingsRef.current = settings;
        clearUnsavedChanges(TAB_NAME);
      } catch (error) {
        console.error("Error updating integrations:", error);
      }
    }
  }, [
    isDirty,
    settings,
    clearUnsavedChanges,
    updateIntegrations,
    selectedCompany,
  ]);

  useEffect(() => {
    setExternalSaveFunction(TAB_NAME, handleSaveChanges);
  }, [handleSaveChanges, setExternalSaveFunction]);

  const handleDiscardChanges = useCallback(() => {
    setSettings(originalSettingsRef.current);
    clearUnsavedChanges(TAB_NAME);
  }, [clearUnsavedChanges]);

  return (
    <>
      {loading ? (
        <LoadingAnimation text='Loading...' />
      ) : error ? (
        <p>Error: {error.message}</p>
      ) : (
        <>
          <Section title='Linear'>
            {linearInstalled ? (
              <div className='space-y-4'>
                <div className='flex items-center font-medium'>
                  <Switch
                    checked={settings.pushAutoIdentifiedIssues || false}
                    onChange={(event, checked) =>
                      handleInputChange("pushAutoIdentifiedIssues", checked)
                    }
                  />
                  <p>
                    Push auto-identified issues in Sailfish to Engineering
                    Ticketing platform
                  </p>
                </div>
                <Autocomplete
                  options={linearTeams}
                  getOptionLabel={(option) => option.name}
                  value={
                    linearTeams.find(({ id }) => id == settings.defaultTeam) ||
                    null
                  }
                  onChange={(event, value) =>
                    handleInputChange("defaultTeam", value?.id || "")
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Default Team'
                      variant='outlined'
                    />
                  )}
                />
                <Autocomplete
                  options={linearProjects}
                  getOptionLabel={(option) => option.name}
                  value={
                    linearProjects.find(
                      ({ id }) => id == settings.defaultProject,
                    ) || null
                  }
                  onChange={(event, value) =>
                    handleInputChange("defaultProject", value?.id || "")
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Default Project'
                      variant='outlined'
                    />
                  )}
                />
                <Autocomplete
                  disableClearable
                  options={LINEAR_PRIORITIES}
                  getOptionLabel={(option) => option.name} // Maps `name` for display
                  value={LINEAR_PRIORITIES.find(
                    ({ value }) => value == settings.defaultPriority,
                  )}
                  onChange={(event, newValue) =>
                    handleInputChange("defaultPriority", newValue?.value || 0)
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  } // Compares `value`
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Default Priority'
                      variant='outlined'
                    />
                  )}
                />
              </div>
            ) : (
              <div className='flex items-center'>
                <IntegrationButton
                  logo={LinearLogo}
                  name={LINEAR}
                  to={`${LINEAR_OAUTH_URI}&client_id=${linearClientId}`}
                />
              </div>
            )}
          </Section>
          {isDirty(TAB_NAME) && (
            <ActionBanner
              onSave={() => handleSaveChanges()}
              onDiscard={() => handleDiscardChanges()}
              onUndo={() => {}}
              onRedo={() => {}}
              canUndo={false}
              canRedo={false}
            />
          )}
        </>
      )}
    </>
  );
};

export default Integrations;
