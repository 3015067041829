import { useMutation } from "@apollo/client";
import rrwebPlayer from "@sailfish-rrweb/rrweb-player";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import "rrweb-player/dist/style.css";
import { useAuth } from "../contexts/AuthContext";
import { useCompany } from "../contexts/CompanyContext";
import { CREATE_TRIAGE_MUTATION } from "../graphql/triage";
import ContactSupportLink from "./ContactSupportLink";
import LoadingAnimation from "./LoadingAnimation";
import TimePointDisplayIndividual from "./TimePointDisplayIndividual";
import TimelineForRrwebPlayer, {
  Region,
} from "./Triage/TimelineForRrwebPlayer";
import VideoPlayerControls from "./VideoPlayerControls";
import VideoPlayerRuler from "./VideoPlayerRuler";
import { HighlightedSection, TimelineEvent } from "../utils/interfaces";
import IconButton from "./IconButton";

interface RRWebPlayerProps {
  id?: string;
  events: any[];
  timelineEvents: TimelineEvent[];
  highlightedSections: HighlightedSection[];
  width: number;
  height: number;
  initialTime?: number | null;
  onTimeUpdate?: (currentTime: number) => void;
  containerWidth: number;
  eventsError: boolean;
  loadingEvents: boolean;
  handleBackwardRecording: () => void;
  handleForwardRecording: () => void;
  backwardRecordingDisabled: boolean;
  forwardRecordingDisabled: boolean;
  hideTriageButton?: boolean;
}

const DEFAULT_VIDEO_DURATION = 577; // 9m 37s
const DEFAULT_REGION: Region = { start: 35, end: 65 };

//
//
// TODO - add the events to the timeline...
//
//

const RRWebPlayer = forwardRef<any, RRWebPlayerProps>(
  (
    {
      id,
      events,
      width,
      height,
      initialTime,
      onTimeUpdate,
      timelineEvents,
      highlightedSections,
      containerWidth,
      eventsError,
      loadingEvents,
      handleBackwardRecording,
      handleForwardRecording,
      backwardRecordingDisabled,
      forwardRecordingDisabled,
      hideTriageButton,
    },
    ref,
  ) => {
    const playerRef = useRef<any>(null);
    const playerContainerRef = useRef<HTMLDivElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [playbackSpeed, setPlaybackSpeed] = useState(1);
    const [currentTime, setCurrentTime] = useState(0);
    const [totalTime, setTotalTime] = useState(0);
    const [skipInactivity, setSkipInactivity] = useState(true);
    const [displayOnly, setDisplayOnly] = useState(true); // State to toggle displayOnly
    const [selectedRegion, setSelectedRegion] = useState<Region | null>(null);
    const [startTime, setStartTime] = useState<number>(0);
    const [endTime, setEndTime] = useState<number>(0);
    const { selectedCompany } = useCompany(); // Use the useCompany hook to get the selected company
    const { user } = useAuth();
    const navigate = useNavigate();

    const [createTriage] = useMutation(CREATE_TRIAGE_MUTATION);
    const handleCreateTriage = () => {
      if (!selectedRegion) {
        alert(
          "Please select at least one time window before creating a triage.",
        );
        return;
      }

      // Mapping selected regions to match triageInfoRecordings or triageInfoLogs
      const triageInfoRecording = {
        recordingId: id!,
        identifiedProblemWindows: [
          {
            timestampStart: Math.round(selectedRegion.start),
            timestampEnd: Math.round(selectedRegion.end),
          },
        ],
      };

      createTriage({
        variables: {
          triageInfoRecordings: [triageInfoRecording],
          triageInfoLogs: null, // Assuming only recordings are handled here, update if necessary
          companyId: selectedCompany ? parseInt(selectedCompany.value, 10) : 0,
        },
      })
        .then((response) => {
          if (response.data?.createTriage) {
            navigate(`/triage/${response.data.createTriage.id}`, {
              state: { from: "RRWebPlayerWithTriage" },
            });
          }
        })
        .catch((err) => {
          console.error("Error creating triage:", err);
        });
    };

    useImperativeHandle(ref, () => ({
      play() {
        playerRef.current.play();
        setIsPlaying(true);
      },
      pause() {
        playerRef.current.pause();
        setIsPlaying(false);
      },
      seekToPercentage(time: number) {
        playerRef.current.goto(time);
        setCurrentTime(time);
      },
      seekToTime(time: number) {
        playerRef.current.goto(time - startTime);
        setCurrentTime(time - startTime);
      },
      resetPlayer() {
        playerRef.current.goto(0);
        playerRef.current.play();
        setCurrentTime(0);
        setIsPlaying(true);
      },
    }));

    useEffect(() => {
      if (!id || !events) return;

      if (playerRef.current) {
        playerRef.current.pause();
      }

      if (!playerRef.current) {
        playerRef.current = new rrwebPlayer({
          target: document.getElementById("rrweb-player")!,
          props: {
            events,
            autoPlay: false,
            showController: false,
            width: width,
            height: height,
            inactivePeriodThreshold: 10 * 1000,
            skipInactive: skipInactivity,
          },
        });

        const playerMetadata = playerRef.current.getMetaData();
        setStartTime(playerMetadata.startTime);
        setEndTime(playerMetadata.endTime);
        setTotalTime(playerMetadata.totalTime);

        if (highlightedSections.length) {
          setSelectedRegion({
            start: highlightedSections[0].startTime,
            end: highlightedSections[0].endTime,
          });
        }

        if (initialTime) {
          playerRef.current.goto(initialTime);
        }

        playerRef.current.addEventListener(
          "ui-update-current-time",
          (event: any) => {
            setCurrentTime(event.payload);
          },
        );

        playerRef.current.addEventListener(
          "ui-update-player-state",
          (event: any) => {
            if (event.payload === "paused") {
              setIsPlaying(false);
            }
          },
        );
      } else {
        playerRef.current.$set({
          events,
          width,
          height,
        });
        playerRef.current.triggerResize();

        if (initialTime) {
          playerRef.current.goto(initialTime);
        }
      }
    }, [id, events, width, height, initialTime]);

    const togglePlayPause = () => {
      if (!isPlaying) {
        playerRef.current.goto(currentTime);
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
      setIsPlaying(!isPlaying);
    };

    const changeSpeed = (speed: number) => {
      setPlaybackSpeed(speed);
      playerRef.current.setSpeed(speed);
    };

    const toggleSkipInactivity = () => {
      playerRef.current.toggleSkipInactive();
      setSkipInactivity((prev) => !prev);
    };

    const toggleDisplayMode = () => {
      setSelectedRegion(
        selectedRegion
          ? null
          : {
              start: getTimeByPercentage(DEFAULT_REGION.start),
              end: getTimeByPercentage(DEFAULT_REGION.end),
            },
      ); // Select/Unselect region on display mode toggle
      setDisplayOnly((prev) => !prev);
    };

    const inactiveTimes = (playerRef.current?.inactivePeriods() || []) as [
      number,
      number,
    ][];

    // Calculate total active time
    const totalActiveTime = useMemo(
      () =>
        inactiveTimes.reduce(
          (totalTime, [start, end]) => totalTime - (end - start),
          totalTime,
        ),
      [inactiveTimes, totalTime],
    );

    const timelineEventsMapped = timelineEvents.map((event) => ({
      time: event.time - startTime,
      // time: ((event.time - startTime) / totalTime) * 100,
      // time: event.time,
      icon: <event.Icon className='text-primary-blue-100' />,
      color: event.color !== undefined ? event.color : "blue", // Assuming a default color, adjust as needed
      text: event.text, // Adjust if you need a different text
    }));

    const formattedInactiveTimes = useMemo(
      () =>
        skipInactivity
          ? // Calculate inactive time points position when skipping inactivity
            inactiveTimes.reduce<[Region[], number]>(
              ([formattedInactiveTimes, diff], [start, end]) => {
                const adjustedStart =
                  ((start - startTime - diff) / totalActiveTime) * 100;
                const duration = end - start;
                formattedInactiveTimes.push({
                  start: adjustedStart,
                  end: adjustedStart,
                });
                return [formattedInactiveTimes, diff + duration];
              },
              [[], 0],
            )[0]
          : inactiveTimes.map(([start, end]) => {
              const adjustedStart = ((start - startTime) / totalTime) * 100;
              const adjustedEnd = ((end - startTime) / totalTime) * 100;
              return {
                start: adjustedStart,
                end: adjustedEnd,
              };
            }),
      [skipInactivity, inactiveTimes],
    );

    const disabled = !id || !events;
    const videoDurationSeconds = totalTime
      ? totalTime / 1000
      : DEFAULT_VIDEO_DURATION;

    const getActivePeriod = (inactivePeriodIndex: number) => {
      // Get active period start and end based on the next inactive period. Default period - full bar
      const activePeriod = {
        start: startTime,
        end: endTime,
        startPercentage: 0,
        endPercentage: 100,
      };

      if (!inactiveTimes.length) {
        return activePeriod;
      }

      if (inactivePeriodIndex < 0) {
        // Last piece, previous inactive period end is the current active period start
        activePeriod.start = inactiveTimes[inactiveTimes.length - 1][1];
        activePeriod.startPercentage =
          formattedInactiveTimes[formattedInactiveTimes.length - 1].end;
        return activePeriod;
      }
      if (inactivePeriodIndex === 0) {
        // First piece, next inactive period start is the current active period end
        activePeriod.end = inactiveTimes[inactivePeriodIndex][0];
        activePeriod.endPercentage =
          formattedInactiveTimes[inactivePeriodIndex].start;
        return activePeriod;
      }
      // Previous inactive period end is the current active period star
      activePeriod.start = inactiveTimes[inactivePeriodIndex - 1][1];
      // Next inactive period start is the current active period end
      activePeriod.end = inactiveTimes[inactivePeriodIndex][0];
      activePeriod.startPercentage =
        formattedInactiveTimes[inactivePeriodIndex - 1].end;
      activePeriod.endPercentage =
        formattedInactiveTimes[inactivePeriodIndex].start;
      return activePeriod;
    };

    // Get left position percentage in timeline bar by time
    const getPercentageByTime = (time: number) => {
      const timeStamp = startTime + time;
      const inactivePeriodIndex = inactiveTimes.findIndex(
        ([start]) => timeStamp < start,
      ); // Get next inactive period index

      const inactiveTime = inactiveTimes.findIndex(
        ([start, end]) => timeStamp >= start && timeStamp <= end,
      );

      const { start, end, startPercentage, endPercentage } =
        inactiveTime === -1
          ? getActivePeriod(inactivePeriodIndex) // In active period
          : {
              start: inactiveTimes[inactiveTime][0],
              end: inactiveTimes[inactiveTime][1],
              startPercentage: formattedInactiveTimes[inactiveTime].start,
              endPercentage: formattedInactiveTimes[inactiveTime].end,
            }; // In inactive period

      const timeInCurrentPeriod = timeStamp - start;
      const currentPeriodDuration = end - start;
      const currentPeriodLength = endPercentage - startPercentage;
      return (
        startPercentage +
        (timeInCurrentPeriod / currentPeriodDuration) * currentPeriodLength
      );
    };

    // Get time by left position percentage in timeline bar
    const getTimeByPercentage = (percentage: number) => {
      const inactivePeriodIndex = formattedInactiveTimes.findIndex(
        ({ start }) => percentage < start,
      ); // Get next inactive period index

      const { start, end, startPercentage, endPercentage } =
        getActivePeriod(inactivePeriodIndex);
      const percentageInCurrentPeriod = percentage - startPercentage;
      const currentPeriodDuration = end - start;
      const currentPeriodLength = endPercentage - startPercentage;
      return (
        (percentageInCurrentPeriod / currentPeriodLength) *
          currentPeriodDuration +
        (start - startTime)
      );
    };

    return (
      <div
        id='rrweb-tile'
        ref={playerContainerRef}
        className='w-full h-full flex flex-col'
      >
        <div className='shadow-md border border-gray-200 rounded-lg bg-white h-[470px] flex flex-col py-1 px-2 justify-center'>
          {loadingEvents ? (
            <LoadingAnimation text='Loading...' />
          ) : id ? (
            <div id='rrweb-player' className='flex justify-center'></div>
          ) : (
            <div className='text-center text-sm leading-6 text-zinc-700 flex flex-col gap-4'>
              <div>
                😊 <b>Hello {user?.name}</b>
                <br />
                {eventsError
                  ? "Select a different recording; current recording is unfetchable"
                  : "Select Video from the list on the right to get started!"}
                <br />
                You can Search through the <b>Users ID</b> or{" "}
                <b>Filter by a date</b>
              </div>
              <div>
                Click the tool bar to view <b>Events</b> or{" "}
                <b>User Meta Data</b>
              </div>
              <ContactSupportLink />
              <NavLink
                to='https://docs.sailfishqa.com/'
                className='link text-xs leading-5 font-bold text-blue-700'
                target='_blank'
              >
                💪 Learn more about Recordings
              </NavLink>
            </div>
          )}
        </div>
        <div id='rrweb-playback-bar' className='relative mt-8 z-10'>
          {disabled ? (
            // Display empty timeline
            <div className='flex items-center rounded'>
              <div className='w-full bg-slate-100 h-3 rounded-full' />
            </div>
          ) : (
            <TimelineForRrwebPlayer
              playPosition={getPercentageByTime(currentTime)}
              totalTime={totalTime}
              currentTotalTime={
                skipInactivity && !disabled ? totalActiveTime : totalTime
              }
              events={timelineEventsMapped} // Pass the mapped events
              inactivePeriods={formattedInactiveTimes} // Pass the inactive periods
              onTimelineClick={(percentage: number) => {
                const newTime = getTimeByPercentage(percentage);
                setCurrentTime(newTime);
                playerRef.current.goto(newTime);
              }}
              displayOnly={displayOnly} // Pass the displayOnly state
              selectedRegion={selectedRegion} // Pass selected region to Timeline
              setSelectedRegion={setSelectedRegion} // Allow Timeline to update selected region
              containerWidth={containerWidth}
              getPercentageByTime={getPercentageByTime}
              getTimeByPercentage={getTimeByPercentage}
            />
          )}
          <div className='flex relative'>
            <TimePointDisplayIndividual
              containerWidth={containerWidth}
              videoDurationSeconds={
                skipInactivity && !disabled && totalActiveTime
                  ? totalActiveTime / 1000
                  : videoDurationSeconds
              }
              disabled={disabled}
            />
            <IconButton
              size='text-base'
              icon={AiOutlineQuestionCircle}
              tooltip='In hide inactivity mode points rely on active period duration'
              className='absolute right-0 mr-[-8px] !bg-transparent'
              noShadow
            />
          </div>
          <VideoPlayerRuler containerWidth={containerWidth} />
          <VideoPlayerControls
            videoDurationSeconds={videoDurationSeconds}
            currentTime={currentTime}
            playbackSpeed={playbackSpeed}
            disabled={disabled}
            displayOnly={displayOnly}
            backwardRecordingDisabled={backwardRecordingDisabled}
            forwardRecordingDisabled={forwardRecordingDisabled}
            skipInactivity={skipInactivity}
            isPlaying={isPlaying}
            isDefaultRegion={selectedRegion === DEFAULT_REGION}
            hideTriageButton={hideTriageButton}
            changeSpeed={changeSpeed}
            handleCreateTriage={handleCreateTriage}
            handleBackwardRecording={handleBackwardRecording}
            handleForwardRecording={handleForwardRecording}
            toggleDisplayMode={toggleDisplayMode}
            togglePlayPause={togglePlayPause}
            toggleSkipInactivity={toggleSkipInactivity}
          />
        </div>
      </div>
    );
  },
);

export default RRWebPlayer;
