import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

interface Event {
  time: number;
  icon: React.ReactNode;
  color: string;
  text: string;
}

export interface Region {
  start: number;
  end: number;
}

interface TimelineProps {
  playPosition: number;
  totalTime: number;
  currentTotalTime: number;
  events: Event[];
  inactivePeriods?: { start: number; end: number }[]; // Make it optional
  onTimelineClick: (percentage: number) => void;
  displayOnly?: boolean; // Optional prop to enable display-only mode
  selectedRegion: Region | null; // Pass selected region to Timeline
  setSelectedRegion: React.Dispatch<React.SetStateAction<Region | null>>; // Allow Timeline to update selected region
  containerWidth: number;
  getPercentageByTime: (time: number) => number;
  getTimeByPercentage: (percentage: number) => number;
}

const TimelineForRrwebPlayer: React.FC<TimelineProps> = ({
  playPosition,
  totalTime,
  currentTotalTime,
  events,
  inactivePeriods = [], // Default to an empty array
  onTimelineClick,
  displayOnly = false,
  selectedRegion,
  setSelectedRegion,
  containerWidth,
  getPercentageByTime,
  getTimeByPercentage,
}) => {
  const sortedEvents = useMemo(
    () => events.sort((a, b) => a.time - b.time),
    [events],
  );

  const [hoveredEvent, setHoveredEvent] = useState<Event | null>(null);
  const [dragStartPosition, setDragStartPosition] = useState<number | null>(
    null,
  );
  const [dragHandleInfo, setDragHandleInfo] = useState<{
    side: "left" | "right" | "region";
  } | null>(null);

  const timelineRef = useRef<HTMLDivElement | null>(null);

  const handleDragMove = useCallback(
    (e: MouseEvent) => {
      if (!dragHandleInfo || !timelineRef.current || displayOnly) return;

      const rect = timelineRef.current.getBoundingClientRect();
      const percentage = Math.max(
        0,
        Math.min(100, ((e.clientX - rect.left) / rect.width) * 100),
      ); // Ensure percentage stays between 0 and 100

      if (dragHandleInfo.side === "left" && selectedRegion) {
        // Resize from the left
        setSelectedRegion({
          ...selectedRegion,
          start: Math.min(
            getTimeByPercentage(percentage),
            selectedRegion.end - (16 / containerWidth) * currentTotalTime, // Prevent overlap
          ),
        });
      } else if (dragHandleInfo.side === "right" && selectedRegion) {
        // Resize from the right
        setSelectedRegion({
          ...selectedRegion,
          end: Math.max(getTimeByPercentage(percentage), selectedRegion.start), // Prevent overlap
        });
      } else if (
        dragHandleInfo.side === "region" &&
        selectedRegion &&
        dragStartPosition !== null
      ) {
        // Drag the entire region without resizing
        const delta = percentage - dragStartPosition;
        const newStart = Math.max(
          0,
          getTimeByPercentage(
            getPercentageByTime(selectedRegion.start) + delta,
          ),
        );
        const newEnd = Math.min(
          totalTime,
          getTimeByPercentage(getPercentageByTime(selectedRegion.end) + delta),
        );
        const size =
          getPercentageByTime(selectedRegion.end) -
          getPercentageByTime(selectedRegion.start);

        const start =
          delta > 0
            ? getTimeByPercentage(getPercentageByTime(newEnd) - size)
            : newStart;
        const end =
          delta < 0
            ? getTimeByPercentage(getPercentageByTime(newStart) + size)
            : newEnd;

        setSelectedRegion({
          start,
          end,
        });
        setDragStartPosition(percentage); // Update the drag start position
      }
    },
    [
      dragHandleInfo,
      displayOnly,
      dragStartPosition,
      selectedRegion,
      containerWidth,
    ],
  );

  const handleDragEnd = useCallback(() => {
    if (displayOnly) return;
    setDragStartPosition(null);
    setDragHandleInfo(null);
  }, [displayOnly]);

  useEffect(() => {
    if (dragHandleInfo !== null) {
      window.addEventListener("mousemove", handleDragMove);
      window.addEventListener("mouseup", handleDragEnd);
    } else {
      window.removeEventListener("mousemove", handleDragMove);
      window.removeEventListener("mouseup", handleDragEnd);
    }
    // Clean up event listeners
    return () => {
      window.removeEventListener("mousemove", handleDragMove);
      window.removeEventListener("mouseup", handleDragEnd);
    };
  }, [dragHandleInfo, handleDragMove, handleDragEnd]);

  const renderActiveInactiveBackground = () => {
    return inactivePeriods.map((region, index) => (
      <div
        key={index}
        className={`absolute h-full ${
          region.end === region.start ? "bg-yellow-400" : "bg-blue-50"
        }`}
        style={{
          left: `${region.start}%`,
          width:
            region.end === region.start ? 3 : `${region.end - region.start}%`,
        }}
      />
    ));
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!displayOnly) return;
    const rect = timelineRef.current?.getBoundingClientRect();
    if (rect) {
      const percentage = ((e.clientX - rect.left) / rect.width) * 100;
      onTimelineClick(percentage);
    }
  };

  const handleEventHover = (event: Event | null) => {
    setHoveredEvent(event);
  };

  return (
    <div className='relative flex items-center shadow-md h-3 rounded'>
      <div
        ref={timelineRef}
        className='relative w-full h-3 bg-blue-300 rounded-full border border-blue-300'
        onClick={handleClick}
      >
        {/* Render Active/Inactive Background */}
        {renderActiveInactiveBackground()}

        {/* Play position background */}
        <div
          className='absolute h-full rounded-full bg-blue-600 border border-blue-600'
          style={{ width: `${playPosition}%` }}
        />

        {selectedRegion && (
          <div
            className='absolute top-[-12px] z-10 min-w-4 rounded-[10px] select-none h-[74px]'
            style={{
              left: `${getPercentageByTime(selectedRegion.start)}%`,
              width: `${
                getPercentageByTime(selectedRegion.end) -
                getPercentageByTime(selectedRegion.start)
              }%`,
            }}
            onMouseDown={(e) => e.stopPropagation()}
          >
            {/* Left Resize Handle */}
            <div
              className={`absolute left-0 top-0 h-full w-2 bg-[#FF09D8] rounded-l-[10px] ${
                displayOnly ? "" : "cursor-ew-resize"
              }`}
              onMouseDown={(e) => {
                if (e.button !== 0) return;
                e.stopPropagation();
                setDragHandleInfo({ side: "left" });
              }}
            />
            {/* Right Resize Handle */}
            <div
              className={`absolute right-0 top-0 h-full w-2 bg-[#FF09D8] rounded-r-[10px] ${
                displayOnly ? "" : "cursor-ew-resize"
              }`}
              onMouseDown={(e) => {
                if (e.button !== 0) return;
                e.stopPropagation();
                setDragHandleInfo({ side: "right" });
              }}
            />
            {/* Region Drag Handle */}
            <div
              className={`absolute inset-0 mx-2 bg-[#F7D3FD] opacity-30 ${
                displayOnly
                  ? ""
                  : dragHandleInfo?.side === "region"
                  ? "cursor-grabbing"
                  : "cursor-grab"
              }`}
              onMouseDown={(e) => {
                if (e.button !== 0) return;
                e.stopPropagation();
                setDragHandleInfo({ side: "region" });
                setDragStartPosition(
                  ((e.clientX -
                    timelineRef.current!.getBoundingClientRect().left) /
                    timelineRef.current!.getBoundingClientRect().width) *
                    100,
                );
              }}
            />
          </div>
        )}

        {sortedEvents.map((event, index) => (
          <div
            key={index}
            className='absolute flex items-center justify-center top-[-14px] -translate-x-1/2 -translate-y-1/2 px-1.5 py-0.5 rounded bg-white shadow-sm z-20'
            style={{
              left: `${getPercentageByTime(event.time)}%`,
            }}
            onMouseEnter={() => handleEventHover(event)} // Show tooltip on hover
            onMouseLeave={() => handleEventHover(null)} // Hide tooltip when not hovering
          >
            <div
              className='rounded-full'
              style={{ backgroundColor: event.color }}
            >
              {event.icon}
              <div className='absolute bottom-[-8px] left-1/2 -translate-x-1/2 h-0 w-0 border-x-[6px] border-x-transparent border-t-[8px] border-t-white z-20' />
            </div>
          </div>
        ))}
        {hoveredEvent && (
          <div
            ref={(el) => {
              if (el) {
                const parentWidth =
                  el.parentElement?.getBoundingClientRect().width || 0;
                const tooltipWidth = el.getBoundingClientRect().width;
                const leftPosition =
                  (hoveredEvent.time / currentTotalTime) * parentWidth;

                let newTransform = "translateX(-50%)"; // Default is centered
                if (leftPosition + tooltipWidth / 2 > parentWidth) {
                  newTransform = "translateX(-100%)"; // Shift fully left if overflow to the right
                } else if (leftPosition - tooltipWidth / 2 < 0) {
                  newTransform = "translateX(0%)"; // Shift fully right if overflow to the left
                }

                el.style.transform = newTransform;
              }
            }}
            className='absolute p-2 bg-black text-white rounded-md z-20'
            style={{
              left: `${getPercentageByTime(hoveredEvent.time)}%`,
              top: "-70px", // Adjust as needed for correct positioning above the icon
              whiteSpace: "nowrap",
            }}
          >
            {hoveredEvent.text}
          </div>
        )}
      </div>
    </div>
  );
};

export default TimelineForRrwebPlayer;
