import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { FaBug } from "react-icons/fa";
import { FiShare } from "react-icons/fi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  GetRecordingSessionsQuery,
  RecordingSessionType,
  useCreateIssueMutation,
  useGetEngineeringTicketPlatformIntegrationQuery,
  useGetTriageQuery,
} from "../__generated__/graphql";
import client from "../apolloClient";
import CopyToClipboardButton from "../components/Buttons/CopyToClipboardButton";
import CtaButton from "../components/Buttons/CtaButton";
import ApplicationPageWithCta from "../components/Layout/ApplicationPageWithCta";
import CtaModal from "../components/Modal/CtaModal";
import TriageLoadingScreen from "../components/Navigation/TriageLoadingScreen";
import RecordingsList from "../components/RecordingsListWithEvents";
import RecordingSummary from "../components/RecordingSummary";
import RRWebPlayerWithTriage from "../components/RRWebPlayerWithTriage";
import { useAuth } from "../contexts/AuthContext";
import { useCompany } from "../contexts/CompanyContext";
import useContainerWidth from "../hooks/useContainerWidth";
import useUnzippedEvents from "../hooks/useUnzippedEvents";
import { INTEGRATIONS, LINEAR_PRIORITIES } from "../utils/constants";
import formatRelativeTime from "../utils/dateFns";
import { getDeviceIcon } from "../utils/getDeviceIcon";
import { AutocompleteOption, HighlightedSection } from "../utils/interfaces";
import WebSocketService from "../utils/websocket";

const DEBUG = import.meta.env.VITE_DEBUG ? import.meta.env.VITE_DEBUG : false;

const RECORDINGS_PER_PAGE = 10;
const PLAYER_HEIGHT_PX = 462;

const TriagePage = () => {
  const rrwebPlayerRef = useRef<any>(null);

  const { triageId } = useParams<{ triageId?: string }>();
  const [triageCreationTime, setTriageCreationTime] = useState<Date | null>(
    null,
  );
  const { selectedCompany } = useCompany();
  const [selectedRecording, setSelectedRecording] =
    useState<RecordingSessionType | null>(null);
  const [logsPage, setLogsPage] = useState(1);
  const [networkRequestPage, setNetworkRequestPage] = useState(1);
  const [otherSimilarRecordingsPage, setOtherSimilarRecordingsPage] =
    useState(1);
  const [recordings, setRecordings] = useState<RecordingSessionType[]>([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [highlightedSections, setHighlightedSections] = useState<
    HighlightedSection[]
  >([]);
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [issueName, setIssueName] = useState<string>("");
  const [issueDescription, setIssueDescription] = useState<string>("");
  const [issueTeam, setIssueTeam] = useState("");
  const [issueProject, setIssueProject] = useState("");
  const [issuePriority, setIssuePriority] = useState(0);
  const [errorMessageCreateIssue, setErrorMessageCreateIssue] = useState<
    string | null
  >(null);

  const {
    unzippedEvents,
    timelineEvents,
    eventsError,
    loadingEvents,
    setEventsError,
  } = useUnzippedEvents(selectedRecording?.id);
  const { containerRef, containerWidth } = useContainerWidth();

  useEffect(() => {
    const handleMessage = (message: MessageEvent) => {
      // const data = JSON.parse(message.data);
      // console.log("Data received:", data);
      //
      // if (data.type === "typeA") {
      //   console.log("Page A received:", data);
      //   // Handle messages specific to Page A
      // }
    };

    WebSocketService.subscribe(handleMessage);

    return () => {
      WebSocketService.unsubscribe(handleMessage);
    };
  }, []);

  const calculateTotalPages = (
    totalItems: number,
    itemsPerPage: number,
  ): number => {
    return Math.ceil(totalItems / itemsPerPage);
  };

  const totalPages = calculateTotalPages(totalItemsCount, RECORDINGS_PER_PAGE);

  const location = useLocation();
  const fromPage = location.state?.from || "unknown";

  const shouldShowTriageLoadingScreen = fromPage === "RRWebPlayerWithTriage";

  const handleLoadingComplete = useCallback(() => {
    setIsLoading(false);
  }, []);

  // Create Issue
  const [
    createIssueMutation,
    { loading: createIssueLoading, error: createIssueError },
  ] = useCreateIssueMutation();

  const { data: integrationData } =
    useGetEngineeringTicketPlatformIntegrationQuery({
      variables: {
        provider: INTEGRATIONS.LINEAR,
        companyId: selectedCompany
          ? parseInt(selectedCompany.value, 10)
          : undefined,
      },
      onCompleted: (data) => {
        if (data?.getEngineeringTicketPlatformIntegration) {
          const { installed, defaultPriority, defaultProject, defaultTeam } =
            data.getEngineeringTicketPlatformIntegration;

          if (!installed) {
            return;
          }
          setIssueTeam(defaultTeam || "");
          setIssueProject(defaultProject || "");
          setIssuePriority(defaultPriority || 0);
        }
      },
    });

  const linearProjects: AutocompleteOption[] =
    integrationData?.getEngineeringTicketPlatformIntegration?.projects || [];
  const linearTeams: AutocompleteOption[] =
    integrationData?.getEngineeringTicketPlatformIntegration?.teams || [];
  const linearInstalled =
    integrationData?.getEngineeringTicketPlatformIntegration?.installed;

  // Fetch Triage data including creation time
  const {
    data: triageData,
    loading,
    error,
  } = useGetTriageQuery({
    variables: {
      triageId: triageId ? parseInt(triageId, 10) : 0,
      companyId: selectedCompany
        ? parseInt(selectedCompany.value, 10)
        : undefined,
    },
    client, // Ensure the client is still provided for authentication
    onCompleted: (data) => {
      if (DEBUG) console.log("Query completed. Data received:", data);

      if (data && data.getTriage) {
        setTriageCreationTime(new Date(data.getTriage.createdAt));

        const recordingData = data.getTriage.triageRecordingData;
        const logData = data.getTriage.triageLogData;
        const similarRecordings = data.getTriage.otherSimilarRecordings.map(
          (entry: any) => entry.recording,
        );

        const recordingsFromRecordingData = recordingData.map(
          (data: any) => data.recording,
        );
        const recordingsFromLogData = logData.map(
          (data: any) => data.recording,
        );

        const allRecordings = [
          ...recordingsFromRecordingData,
          ...recordingsFromLogData,
          ...similarRecordings,
        ];

        const uniqueRecordings = Array.from(
          new Map(allRecordings.map((rec) => [rec.id, rec])).values(),
        );

        setRecordings(uniqueRecordings); // Store all unique recordings in state
        setTotalItemsCount(uniqueRecordings.length);

        // ** Prioritize Query Parameter **
        const queryParams = new URLSearchParams(location.search);
        const recordingId = queryParams.get("recordingId");
        if (recordingId) {
          const matchingRecording = uniqueRecordings.find(
            (rec) => rec.id === recordingId,
          );
          if (matchingRecording) {
            setSelectedRecording(matchingRecording);
          }
        } else {
          if (recordingsFromRecordingData.length > 0) {
            setSelectedRecording(recordingsFromRecordingData[0]);
          } else if (recordingsFromLogData.length > 0) {
            setSelectedRecording(recordingsFromLogData[0]);
          } else if (similarRecordings.length > 0) {
            setSelectedRecording(similarRecordings[0]);
          }
        }

        // Process timeWindows to create HighlightedSections
        const sections: HighlightedSection[] = recordingData.flatMap(
          (data: any) =>
            data.timeWindows.map((window: any) => ({
              startTime: window.timestampStart,
              endTime: window.timestampEnd,
              text: 'Marked as "Error" Segment',
              color: "#fcd34d",
            })),
        );

        setHighlightedSections(sections);
      } else {
        console.warn("Triage data not available or incomplete:", data);
      }
    },
    onError: (error) => {
      console.error("Error fetching triage:", error);
      setEventsError(true);
    },
  });

  // Calculate time delta for loading animation
  const calculateAnimationOffset = () => {
    if (!triageCreationTime) return 0;
    const now = new Date();
    return Math.max(0, now.getTime() - triageCreationTime.getTime());
  };

  if (error || eventsError) return <div>Error loading data</div>;

  const handlePageChangeLogs = (newPage: number) => {
    setLogsPage(newPage);
  };
  const handlePageChangeNetworkRequests = (newPage: number) => {
    setNetworkRequestPage(newPage);
  };
  const handlePageChangeOtherSimilarRecordings = (newPage: number) => {
    setOtherSimilarRecordingsPage(newPage);
  };

  const handleSelectRecording = (
    recording: NonNullable<
      GetRecordingSessionsQuery["recordingSessions"]
    >["items"][number],
  ) => {
    // Update the URL with the selected recording while staying on the same page
    navigate(`?recordingId=${recording.id}`, { replace: true });

    // Update the selected recording state
    setSelectedRecording(recording as RecordingSessionType);
  };

  const defaultContent = "-";

  const occurrenceData = [
    {
      title: "Change/24h",
      content: triageData?.getTriage?.change24h || defaultContent,
    },
    {
      title: "Last Seen",
      content: triageData?.getTriage?.lastSeen
        ? formatRelativeTime(triageData.getTriage.lastSeen)
        : defaultContent,
    },
    {
      title: "Severity",
      content: triageData?.getTriage?.severity || defaultContent,
    },
    {
      title: "Users Affected",
      content: triageData?.getTriage?.affected || defaultContent,
    },
    {
      title: "Devices",
      content:
        triageData?.getTriage?.devices?.map(
          (device, index) =>
            device && (
              <div key={index} className='flex items-center gap-2'>
                {getDeviceIcon(device)}
                <span>
                  {device.device?.brand || "Unknown"}{" "}
                  {device.device?.type || ""}
                </span>
              </div>
            ),
        ) || defaultContent,
    },
  ];

  const createIssue = async () => {
    if (DEBUG) console.log("createIssue");
    if (!issueName.trim() || !issueDescription.trim()) {
      setErrorMessageCreateIssue(
        "Both 'Issue Name' and 'Description' fields are required.",
      );
      return;
    }
    if (!triageId) {
      console.error("Unable to create issue - triageId is not set");
      return;
    }

    // Clear any existing error messages
    setErrorMessageCreateIssue(null);

    try {
      // Execute the createIssue mutation
      const { data } = await createIssueMutation({
        variables: {
          input: {
            title: issueName.trim(),
            description: issueDescription.trim(),
            triageIds: [triageId],
          },
          teamId: issueTeam,
          projectId: issueProject,
          priority: issuePriority,
          companyId: selectedCompany
            ? parseInt(selectedCompany.value, 10)
            : undefined,
        },
      });
      if (!data) {
        console.error("Unable to create issue");
        return;
      }

      if (data.createIssue) {
        // Navigate to the newly created issue page
        navigate(`/issues/${data.createIssue.id}`);
      } else {
        setErrorMessageCreateIssue(
          "Failed to create the issue. Please try again.",
        );
      }
    } catch (error) {
      console.error("Error creating issue:", error);
      setErrorMessageCreateIssue(
        "An error occurred while creating the issue. Please try again.",
      );
    }
  };

  const pageName = `Triages > Triage ${triageId}`;

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const currentRecordingIndex = recordings.findIndex(
    ({ id }) => id === selectedRecording?.id,
  );
  const isFirstRecording = currentRecordingIndex === 0;
  const isLastRecording = currentRecordingIndex === recordings.length - 1;

  const handleBackwardRecording = () => {
    if (isFirstRecording) {
      handlePageChangeOtherSimilarRecordings(otherSimilarRecordingsPage - 1);
    } else {
      handleSelectRecording(recordings[currentRecordingIndex - 1]);
    }
  };

  const handleForwardRecording = () => {
    if (isLastRecording) {
      handlePageChangeOtherSimilarRecordings(otherSimilarRecordingsPage + 1);
    } else {
      handleSelectRecording(recordings[currentRecordingIndex + 1]);
    }
  };

  const backwardRecordingDisabled =
    isFirstRecording && otherSimilarRecordingsPage === 1;
  const forwardRecordingDisabled =
    isLastRecording && otherSimilarRecordingsPage === totalPages;

  const createTicketFields = [
    <Autocomplete
      key='Team'
      options={linearTeams}
      getOptionLabel={(option) => option.name}
      value={linearTeams.find(({ id }) => id == issueTeam) || null}
      onChange={(event, value) => setIssueTeam(value?.id || "")}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField {...params} label='Team' variant='outlined' />
      )}
    />,
    <Autocomplete
      key='Project'
      options={linearProjects}
      getOptionLabel={(option) => option.name}
      value={linearProjects.find(({ id }) => id == issueProject) || null}
      onChange={(event, value) => setIssueProject(value?.id || "")}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField {...params} label='Project' variant='outlined' />
      )}
    />,
    <Autocomplete
      key='Priority'
      disableClearable
      options={LINEAR_PRIORITIES}
      getOptionLabel={(option) => option.name} // Maps `name` for display
      value={LINEAR_PRIORITIES.find(({ value }) => value == issuePriority)}
      onChange={(event, newValue) => setIssuePriority(newValue?.value || 0)}
      isOptionEqualToValue={(option, value) => option.value === value.value} // Compares `value`
      renderInput={(params) => (
        <TextField {...params} label='Priority' variant='outlined' />
      )}
    />,
  ];

  return (
    <ApplicationPageWithCta
      pageName={pageName}
      helpLink='https://docs.sailfishqa.com/'
      helpTooltip='Click here to learn about Triages.'
      CtaButton={
        <div className='flex gap-2'>
          <CopyToClipboardButton
            title='Share'
            startIcon={<FiShare size={16} />}
          />
          <CtaButton
            titlePrimary='Create Issue'
            icon={<FaBug />}
            bgPrimaryClass='bg-red-500'
            textPrimaryClass='text-white'
            bgPrimaryHoverClass='hover:bg-red-600'
            textPrimaryHoverClass='hover:text-white'
            onClick={handleOpenModal}
            showOutlineHoverPrimary={false}
            additionalClassNames='!px-2.5 h-8 rounded-md text-sm leading-6 font-medium shadow-md'
            additionalWidth='4px'
            startIcon
          />
        </div>
      }
    >
      {isModalOpen && (
        <CtaModal
          title='Create Issue'
          closeModal={handleCloseModal}
          fields={[
            {
              label: "Issue Name",
              type: "input",
              placeholderText: "Enter a name for the issue",
              onChange: (e) => setIssueName(e.target.value),
            },
            {
              label: "Description",
              type: "textarea",
              placeholderText: "Users are experiencing...",
              onChange: (e) => setIssueDescription(e.target.value),
            },
          ]}
          additionalFields={linearInstalled ? createTicketFields : []}
          buttons={[
            {
              button: (
                <CtaButton
                  titlePrimary='Cancel'
                  bgPrimaryClass='bg-gray-300'
                  textPrimaryClass='text-secondary'
                  bgPrimaryHoverClass='hover:bg-gray-500'
                  textPrimaryHoverClass='hover:text-primary-dark'
                  outlinePrimaryHoverClass='hover:outline hover:outline-2 hover:outline-offset-0 hover:outline-gray-800'
                  additionalClassNames='w-full'
                  onClick={handleCloseModal}
                />
              ),
              width: 50,
            },
            {
              button: (
                <CtaButton
                  titlePrimary='Save'
                  additionalClassNames='w-full'
                  fontBold={true}
                  onClick={createIssue}
                />
              ),
              width: 50,
            },
          ]}
        />
      )}
      <div className='relative flex gap-4 flex-1'>
        <div className='flex flex-[5] flex-col max-h-full min-w-0'>
          <div ref={containerRef}>
            <div className='h-full'>
              <RRWebPlayerWithTriage
                id={triageId}
                ref={rrwebPlayerRef}
                events={unzippedEvents}
                timelineEvents={timelineEvents}
                highlightedSections={highlightedSections}
                width={containerWidth - 16}
                height={PLAYER_HEIGHT_PX}
                containerWidth={containerWidth}
                eventsError={eventsError}
                loadingEvents={loadingEvents}
                handleBackwardRecording={handleBackwardRecording}
                handleForwardRecording={handleForwardRecording}
                backwardRecordingDisabled={backwardRecordingDisabled}
                forwardRecordingDisabled={forwardRecordingDisabled}
                hideTriageButton
              />
            </div>
          </div>
          <RecordingSummary recordingId={selectedRecording?.id} />
        </div>
        <div className='flex flex-[2] flex-col gap-2 max-h-full min-w-0'>
          <Accordion
            className='!shadow-md border border-gray-200 !rounded !m-0 flex flex-col p-2.5 overflow-auto min-h-14'
            defaultExpanded
            classes={{ expanded: "min-h-32" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
              className='text-sm font-semibold !min-h-8'
              classes={{ content: "!my-0" }}
            >
              Occurrence Info
            </AccordionSummary>
            <div className='grid grid-cols-2 gap-4 p-4 text-sm leading-[18px]'>
              {occurrenceData.map(({ title, content }) => (
                <div
                  key={title}
                  className='border border-sky-200 rounded-md shadow-md p-2'
                >
                  <div>{title}</div>
                  <div className='font-bold'>{content}</div>
                </div>
              ))}
            </div>
          </Accordion>
          <Accordion
            className='!shadow-md border border-gray-200 !rounded !m-0 flex flex-col p-2.5 overflow-auto'
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel2-content'
              id='panel2-header'
              className='text-sm font-semibold !min-h-8'
              classes={{ content: "!my-0" }}
            >
              Other Similar Recordings
            </AccordionSummary>
            <RecordingsList
              recordings={recordings}
              onSelect={handleSelectRecording}
              page={otherSimilarRecordingsPage}
              totalPages={totalPages}
              onPageChange={handlePageChangeOtherSimilarRecordings}
              loading={loading}
              loadingEvents={loadingEvents}
              recordingId={selectedRecording?.id}
              timelineEvents={timelineEvents}
              activeTooltip='Back to Recording Page'
              inactiveTooltip='Go to Recording Page'
              onClickEvent={(time) => {
                // Seek to the time in the player without changing its play state
                if (rrwebPlayerRef.current) {
                  rrwebPlayerRef.current.seekToTime(time);
                }
              }}
            />
          </Accordion>
        </div>

        {isLoading && shouldShowTriageLoadingScreen && (
          <div className='absolute inset-0 z-10 bg-white bg-opacity-80 flex items-center justify-center'>
            <TriageLoadingScreen
              onComplete={handleLoadingComplete}
              animationOffset={calculateAnimationOffset()}
            />
          </div>
        )}
      </div>
    </ApplicationPageWithCta>
  );
};

export default TriagePage;
