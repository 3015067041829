import { useMutation, useQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select/Select";
import TextField from "@mui/material/TextField";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import client from "../../apolloClient";
import { useCompany } from "../../contexts/CompanyContext";
import { useUnsavedChanges } from "../../contexts/UnsavedChangesContext";
import {
  GET_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS,
} from "../../graphql/settingsQueries";
import ActionBanner from "../ActionBanner";
import EntityTextBox from "../EntityTextBox";
import LoadingAnimation from "../LoadingAnimation";
import Section from "./Section";
import { SettingsComponentProps } from "../../utils/interfaces";

type EmailNotificationSettings = {
  defaultRecipients: string[];
  timeBetweenSuccessiveNotificationsPerIssue: string;
};

type WebhookNotificationSettings = {
  timeBetweenSuccessiveNotificationsPerIssue: string;
};

type Webhook = {
  destinationAddress: string;
  webhookDataType: string;
  authentication: string;
};

interface NotificationSettingsData {
  getNotificationSettings: {
    emailNotificationSettings: EmailNotificationSettings;
    webhookNotificationSettings: WebhookNotificationSettings;
    notificationWebhooks: Webhook[];
  };
}

const parseDuration = (duration: string) => {
  const regex = /P(?:(\d+)D)?T?(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
  const matches = duration.match(regex);
  return {
    days: matches && matches[1] ? parseInt(matches[1]) : 0,
    hours: matches && matches[2] ? parseInt(matches[2]) : 0,
    minutes: matches && matches[3] ? parseInt(matches[3]) : 0,
    seconds: matches && matches[4] ? parseInt(matches[4]) : 0,
  };
};

const StyledNumberInput: React.FC<{
  value: number;
  onChange: (value: number) => void;
  label: string;
  min?: number;
  max?: number;
}> = ({ value, onChange, label, min, max }) => (
  <TextField
    label={label}
    type='number'
    value={value}
    onChange={(e) => onChange(Number(e.target.value))}
    inputProps={{ min, max }}
    variant='outlined'
    fullWidth
  />
);

const NotificationSettings: React.FC<SettingsComponentProps> = ({
  section,
}) => {
  const { selectedCompany } = useCompany();

  const { data, loading, error, refetch } = useQuery<NotificationSettingsData>(
    GET_NOTIFICATION_SETTINGS,
    {
      client,
      variables: {
        companyId: selectedCompany
          ? parseInt(selectedCompany.value, 10)
          : undefined,
      },
    },
  );

  const [updateNotificationSettings] = useMutation(
    UPDATE_NOTIFICATION_SETTINGS,
    {
      client,
    },
  );

  const { setValue, watch, reset } = useForm({
    defaultValues: {
      emailDays: 0,
      emailHours: 0,
      emailMinutes: 0,
      emailSeconds: 0,
      webhookDays: 0,
      webhookHours: 0,
      webhookMinutes: 0,
      webhookSeconds: 0,
    },
  });

  const [emailRecipients, setEmailRecipients] = useState<string[]>([]);
  const [webhooks, setWebhooks] = useState<Webhook[]>([]);

  const originalSettingsRef = useRef<NotificationSettingsData | null>(null);
  const {
    setExternalSaveFunction,
    markSettingAsChanged,
    clearUnsavedChanges,
    isDirty,
  } = useUnsavedChanges();

  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string | null;
  }>({});

  const lastWebhookRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (data) {
      const emailDuration = parseDuration(
        data.getNotificationSettings.emailNotificationSettings
          .timeBetweenSuccessiveNotificationsPerIssue,
      );
      const webhookDuration = parseDuration(
        data.getNotificationSettings.webhookNotificationSettings
          .timeBetweenSuccessiveNotificationsPerIssue,
      );

      setEmailRecipients(
        data.getNotificationSettings.emailNotificationSettings
          .defaultRecipients || [],
      );
      setWebhooks(data.getNotificationSettings.notificationWebhooks || []);

      setValue("emailDays", emailDuration.days);
      setValue("emailHours", emailDuration.hours);
      setValue("emailMinutes", emailDuration.minutes);
      setValue("emailSeconds", emailDuration.seconds);

      setValue("webhookDays", webhookDuration.days);
      setValue("webhookHours", webhookDuration.hours);
      setValue("webhookMinutes", webhookDuration.minutes);
      setValue("webhookSeconds", webhookDuration.seconds);

      originalSettingsRef.current = data;
    }
  }, [data, setValue]);

  const isSettingsChanged = () => {
    if (!originalSettingsRef.current) {
      clearUnsavedChanges("notifications");
      return false;
    }

    const emailDuration = parseDuration(
      originalSettingsRef.current.getNotificationSettings
        .emailNotificationSettings.timeBetweenSuccessiveNotificationsPerIssue,
    );
    const webhookDuration = parseDuration(
      originalSettingsRef.current.getNotificationSettings
        .webhookNotificationSettings.timeBetweenSuccessiveNotificationsPerIssue,
    );

    const currentEmailSettings = {
      days: watch("emailDays"),
      hours: watch("emailHours"),
      minutes: watch("emailMinutes"),
      seconds: watch("emailSeconds"),
    };

    const currentWebhookSettings = {
      days: watch("webhookDays"),
      hours: watch("webhookHours"),
      minutes: watch("webhookMinutes"),
      seconds: watch("webhookSeconds"),
    };

    const originalEmailSettings = {
      days: emailDuration.days,
      hours: emailDuration.hours,
      minutes: emailDuration.minutes,
      seconds: emailDuration.seconds,
    };

    const originalWebhookSettings = {
      days: webhookDuration.days,
      hours: webhookDuration.hours,
      minutes: webhookDuration.minutes,
      seconds: webhookDuration.seconds,
    };

    // Compare email settings
    const emailSettingsChanged =
      JSON.stringify(currentEmailSettings) !==
      JSON.stringify(originalEmailSettings);

    // Compare webhook settings
    const webhookSettingsChanged =
      JSON.stringify(currentWebhookSettings) !==
      JSON.stringify(originalWebhookSettings);

    // Compare webhooks one by one
    const originalWebhooks =
      originalSettingsRef.current.getNotificationSettings.notificationWebhooks;

    let webhooksChanged = false;

    if (webhooks.length !== originalWebhooks.length) {
      webhooksChanged = true;
    } else {
      for (let i = 0; i < webhooks.length; i++) {
        const currentWebhook = webhooks[i];
        const originalWebhook = originalWebhooks[i];

        if (
          currentWebhook.destinationAddress !==
            originalWebhook.destinationAddress ||
          currentWebhook.webhookDataType !== originalWebhook.webhookDataType ||
          currentWebhook.authentication !== originalWebhook.authentication
        ) {
          webhooksChanged = true;
          break;
        }
      }
    }

    const hasChanged =
      emailSettingsChanged || webhookSettingsChanged || webhooksChanged;

    if (hasChanged) {
      markSettingAsChanged("notifications");
    } else {
      clearUnsavedChanges("notifications");
    }

    return hasChanged;
  };

  const handleSaveChanges = useCallback(async () => {
    if (isDirty("notifications")) {
      try {
        await updateNotificationSettings({
          variables: {
            emailInput: {
              defaultRecipients: emailRecipients,
              timeBetweenSuccessiveNotificationsPerIssue: `P${watch(
                "emailDays",
              )}DT${watch("emailHours")}H${watch("emailMinutes")}M${watch(
                "emailSeconds",
              )}S`,
            },
            webhookInput: {
              timeBetweenSuccessiveNotificationsPerIssue: `P${watch(
                "webhookDays",
              )}DT${watch("webhookHours")}H${watch("webhookMinutes")}M${watch(
                "webhookSeconds",
              )}S`,
            },
            webhooks: webhooks.map((webhook) => ({
              destinationAddress: webhook.destinationAddress,
              webhookDataType: webhook.webhookDataType,
              authentication: webhook.authentication,
            })),
          },
        });
        originalSettingsRef.current = {
          getNotificationSettings: {
            emailNotificationSettings: {
              defaultRecipients: emailRecipients,
              timeBetweenSuccessiveNotificationsPerIssue: `P${watch(
                "emailDays",
              )}DT${watch("emailHours")}H${watch("emailMinutes")}M${watch(
                "emailSeconds",
              )}S`,
            },
            webhookNotificationSettings: {
              timeBetweenSuccessiveNotificationsPerIssue: `P${watch(
                "webhookDays",
              )}DT${watch("webhookHours")}H${watch("webhookMinutes")}M${watch(
                "webhookSeconds",
              )}S`,
            },
            notificationWebhooks: webhooks,
          },
        };
        clearUnsavedChanges("notifications");
      } catch (error) {
        console.error("Error updating notification settings:", error);
      }
    }
  }, [
    isDirty,
    emailRecipients,
    webhooks,
    watch,
    updateNotificationSettings,
    clearUnsavedChanges,
  ]);

  const handleDiscardChanges = useCallback(() => {
    if (originalSettingsRef.current?.getNotificationSettings) {
      const emailDuration = parseDuration(
        originalSettingsRef.current.getNotificationSettings
          .emailNotificationSettings.timeBetweenSuccessiveNotificationsPerIssue,
      );
      const webhookDuration = parseDuration(
        originalSettingsRef.current.getNotificationSettings
          .webhookNotificationSettings
          .timeBetweenSuccessiveNotificationsPerIssue,
      );

      setEmailRecipients(
        originalSettingsRef.current.getNotificationSettings
          .emailNotificationSettings.defaultRecipients || [],
      );
      setWebhooks(
        originalSettingsRef.current.getNotificationSettings
          .notificationWebhooks || [],
      );

      setValue("emailDays", emailDuration.days);
      setValue("emailHours", emailDuration.hours);
      setValue("emailMinutes", emailDuration.minutes);
      setValue("emailSeconds", emailDuration.seconds);

      setValue("webhookDays", webhookDuration.days);
      setValue("webhookHours", webhookDuration.hours);
      setValue("webhookMinutes", webhookDuration.minutes);
      setValue("webhookSeconds", webhookDuration.seconds);

      clearUnsavedChanges("notifications");
    }
  }, [clearUnsavedChanges, setValue]);

  useEffect(() => {
    setExternalSaveFunction("notifications", handleSaveChanges);
  }, [handleSaveChanges, setExternalSaveFunction]);

  useEffect(() => {
    if (isDirty("notifications")) {
      handleDiscardChanges();
    }
  }, [section]);

  const handleEmailChange = (recipients: any) => {
    const newRecipients = recipients.map((r: any) => r.value);
    setEmailRecipients(newRecipients);
    isSettingsChanged();
  };

  const handleAddWebhook = () => {
    const newWebhooks = [
      ...webhooks,
      {
        destinationAddress: "",
        webhookDataType: "application/json",
        authentication: "{}",
      },
    ];
    setWebhooks(newWebhooks);
    isSettingsChanged();

    // Scroll to the newly added webhook row
    setTimeout(() => {
      if (lastWebhookRef.current) {
        lastWebhookRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const handleDeleteWebhook = (index: number) => {
    const updatedWebhooks = webhooks.filter((_, i) => i !== index);
    setWebhooks(updatedWebhooks);
    isSettingsChanged();
  };

  // Modified handleWebhookChange to update state immutably
  const handleWebhookChange = (
    index: number,
    field: keyof Webhook,
    value: any,
  ) => {
    const updatedWebhooks = webhooks.map((webhook, i) =>
      i === index ? { ...webhook, [field]: value } : webhook,
    );
    setWebhooks(updatedWebhooks);
  };

  // useEffect to monitor changes in webhooks and other fields
  useEffect(() => {
    isSettingsChanged();
  }, [
    webhooks,
    watch("emailDays"),
    watch("emailHours"),
    watch("emailMinutes"),
    watch("emailSeconds"),
    watch("webhookDays"),
    watch("webhookHours"),
    watch("webhookMinutes"),
    watch("webhookSeconds"),
  ]);

  // Validation function for URL (Webhook URL field)
  const validateUrl = (value: string) => {
    if (!value.trim()) return ""; // No error for empty values
    try {
      new URL(value);
      return ""; // If valid URL, return no error
    } catch {
      return "Please provide a valid URL";
    }
  };

  // Validation function for JSON (Authentication field)
  const validateJson = (value: string) => {
    try {
      // If the value is non-empty, attempt to parse it
      if (value.trim() !== "") {
        JSON.parse(value);
      }
      // If parsing succeeds or the value is empty, return no error
      return "";
    } catch (e) {
      return "Please provide a valid JSON object";
    }
  };

  return (
    <>
      {loading ? (
        <LoadingAnimation text={"Loading..."} />
      ) : error ? (
        <p>Error: {error.message}</p>
      ) : (
        <>
          <Section
            title='Email Notification Settings'
            hide={section !== "email"}
          >
            <div className='space-y-4'>
              <div className='flex items-center space-x-4'>
                <StyledNumberInput
                  label='Days'
                  value={watch("emailDays") || 0}
                  onChange={(value) => {
                    setValue("emailDays", value);
                    isSettingsChanged();
                  }}
                  min={0}
                  max={6}
                />
                <StyledNumberInput
                  label='Hours'
                  value={watch("emailHours") || 0}
                  onChange={(value) => {
                    setValue("emailHours", value);
                    isSettingsChanged();
                  }}
                  min={0}
                  max={23}
                />
                <StyledNumberInput
                  label='Minutes'
                  value={watch("emailMinutes") || 0}
                  onChange={(value) => {
                    setValue("emailMinutes", value);
                    isSettingsChanged();
                  }}
                  min={0}
                  max={59}
                />
                <StyledNumberInput
                  label='Seconds'
                  value={watch("emailSeconds") || 0}
                  onChange={(value) => {
                    setValue("emailSeconds", value);
                    isSettingsChanged();
                  }}
                  min={0}
                  max={59}
                />
              </div>
              <EntityTextBox
                isMulti
                value={emailRecipients.map((email) => ({
                  value: email,
                  label: email,
                }))}
                onChange={handleEmailChange}
                placeholder='Enter email addresses...'
                formatCreateLabel={(inputValue) => inputValue}
                components={{
                  DropdownIndicator: null,
                  IndicatorSeparator: null,
                }}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    display: "none",
                  }),
                  control: (provided) => ({
                    ...provided,
                    minHeight: "48px",
                    borderRadius: "0.5rem",
                    borderColor: "#d1d5db",
                    "&:hover": {
                      borderColor: "#9ca3af",
                    },
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    backgroundColor: "#e5e7eb",
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    color: "#1f2937",
                  }),
                  multiValueRemove: (provided) => ({
                    ...provided,
                    cursor: "pointer",
                    color: "#1f2937",
                    "&:hover": {
                      backgroundColor: "#f87171",
                      color: "white",
                    },
                  }),
                }}
              />
            </div>
          </Section>

          <Section
            title='Webhook Notification Settings'
            hide={section !== "webhook"}
          >
            <div className='space-y-4'>
              <div className='flex items-center space-x-4'>
                <StyledNumberInput
                  label='Days'
                  value={watch("webhookDays") || 0}
                  onChange={(value) => {
                    setValue("webhookDays", value);
                    isSettingsChanged();
                  }}
                  min={0}
                  max={6}
                />
                <StyledNumberInput
                  label='Hours'
                  value={watch("webhookHours") || 0}
                  onChange={(value) => {
                    setValue("webhookHours", value);
                    isSettingsChanged();
                  }}
                  min={0}
                  max={23}
                />
                <StyledNumberInput
                  label='Minutes'
                  value={watch("webhookMinutes") || 0}
                  onChange={(value) => {
                    setValue("webhookMinutes", value);
                    isSettingsChanged();
                  }}
                  min={0}
                  max={59}
                />
                <StyledNumberInput
                  label='Seconds'
                  value={watch("webhookSeconds") || 0}
                  onChange={(value) => {
                    setValue("webhookSeconds", value);
                    isSettingsChanged();
                  }}
                  min={0}
                  max={59}
                />
              </div>

              {/* Webhook List */}
              {webhooks.map((webhook, index) => (
                <div
                  key={index}
                  className='flex items-center space-x-4 mt-2'
                  ref={index === webhooks.length - 1 ? lastWebhookRef : null}
                >
                  {/* Index Number */}
                  <div className='text-center w-8 font-semibold'>
                    {index + 1}
                  </div>

                  {/* Webhook URL */}
                  <div className='flex flex-col flex-1'>
                    <TextField
                      type='url'
                      label='Webhook URL'
                      value={webhook.destinationAddress}
                      onChange={(e) =>
                        handleWebhookChange(
                          index,
                          "destinationAddress",
                          e.target.value,
                        )
                      }
                      onBlur={(e) => {
                        const validationMessage = validateUrl(e.target.value);
                        setValidationErrors({
                          ...validationErrors,
                          [`webhook-url-${index}`]: validationMessage,
                        });
                      }}
                      error={Boolean(validationErrors[`webhook-url-${index}`])}
                      helperText={
                        validationErrors[`webhook-url-${index}`] || ""
                      }
                      fullWidth
                      variant='outlined'
                    />
                  </div>

                  {/* Webhook Data Type */}
                  <Select
                    label='Webhook Data Type'
                    value={webhook.webhookDataType}
                    onChange={(e) =>
                      handleWebhookChange(
                        index,
                        "webhookDataType",
                        e.target.value,
                      )
                    }
                    variant='outlined'
                    className='w-56'
                  >
                    <MenuItem value='application/json'>
                      application/json
                    </MenuItem>
                    <MenuItem value='application/x-www-form-urlencoded'>
                      application/x-www-form-urlencoded
                    </MenuItem>
                  </Select>

                  {/* Authentication Header */}
                  <div className='flex flex-col flex-1'>
                    <TextField
                      type='text'
                      label='Authentication Header (JSON)'
                      value={webhook.authentication}
                      onChange={(e) =>
                        handleWebhookChange(
                          index,
                          "authentication",
                          e.target.value,
                        )
                      }
                      onBlur={(e) => {
                        const validationMessage = validateJson(e.target.value);
                        setValidationErrors({
                          ...validationErrors,
                          [`webhook-auth-${index}`]: validationMessage,
                        });
                      }}
                      error={Boolean(validationErrors[`webhook-auth-${index}`])}
                      helperText={
                        validationErrors[`webhook-auth-${index}`] || ""
                      }
                      fullWidth
                      variant='outlined'
                    />
                  </div>

                  {/* Delete Button */}
                  <IconButton
                    onClick={() => handleDeleteWebhook(index)}
                    style={{
                      backgroundColor: "#f44336",
                      color: "white",
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}

              {/* Add Webhook Button */}
              <div className='mt-4'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleAddWebhook}
                  startIcon={<span style={{ fontSize: "1.5rem" }}>+</span>}
                  style={{
                    borderRadius: "24px",
                    textTransform: "none",
                  }}
                >
                  Add Webhook
                </Button>
              </div>
            </div>
          </Section>
          {isDirty("notifications") && (
            <ActionBanner
              onSave={() => handleSaveChanges()}
              onDiscard={() => handleDiscardChanges()}
              onUndo={() => {}}
              onRedo={() => {}}
              canUndo={false}
              canRedo={false}
            />
          )}
        </>
      )}
    </>
  );
};

export default NotificationSettings;
