import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import GoToButton from "./GoToButton";

interface PaginationProps {
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  page,
  totalPages,
  onPageChange,
}) => {
  return (
    <div className='flex items-center justify-center mb-1'>
      <button
        className={`flex gap-1.5 items-center text-xs leading-[14px] font-medium text-neutral-700 py-2 px-1.5 ${
          page <= 1 ? "opacity-30" : ""
        }`}
        disabled={page <= 1}
        onClick={() => onPageChange(page - 1)}
      >
        <FaChevronLeft /> Previous
      </button>
      <div className='flex gap-2'>
        {page > 1 && (
          <button
            onClick={() => onPageChange(page - 1)}
            className='text-xs font-medium text-neutral-700 w-6 h-6 rounded hover:bg-zinc-100'
          >
            {page - 1}
          </button>
        )}
        <button className='text-xs font-medium text-neutral-700 w-6 h-6 border border-blue-500 rounded hover:bg-zinc-100'>
          {page}
        </button>
        {page < totalPages && (
          <button
            onClick={() => onPageChange(page + 1)}
            className='text-xs font-medium text-neutral-700 w-6 h-6 rounded hover:bg-zinc-100'
          >
            {page + 1}
          </button>
        )}
        <GoToButton totalPages={totalPages} onPageChange={onPageChange} />
      </div>
      <button
        className={`flex gap-1.5 items-center text-xs leading-[14px] font-medium text-neutral-700 py-2 px-1.5 ${
          page >= totalPages ? "opacity-30" : ""
        }`}
        disabled={page >= totalPages}
        onClick={() => onPageChange(page + 1)}
      >
        Next <FaChevronRight />
      </button>
    </div>
  );
};

export default Pagination;
