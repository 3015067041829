import React from "react";
import { PuffLoader } from "react-spinners";

interface LoadingAnimationProps {
  text: string;
  size?: number;
}

const LoadingAnimation: React.FC<LoadingAnimationProps> = ({
  text,
  size = 150,
}) => {
  return (
    <div className='flex justify-center items-center h-full'>
      <div className='text-center'>
        <PuffLoader color='#4A90E2' size={size} />
        <div>{text}</div>
      </div>
    </div>
  );
};

export default LoadingAnimation;
