// import { useRef, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
import ApplicationPage from "../components/ApplicationPage";
// import { useAdmin } from "../contexts/AdminContext";
// import { useAuth } from "../contexts/AuthContext";
// import { useCompany } from "../contexts/CompanyContext";

// interface Recording {
//   id: string;
//   formattedStartTimestamp: string;
//   durationMs: number;
//   userActionsCount: number;
// }

// const DEFAULT_RECORDINGS_PER_PAGE = 25;

// const isDebugMode = false; // import.meta.env.REACT_APP_DEBUG_MODE === "true" || false;

const LogsPage = () => {
  // TODO - set parameters in the URL
  // const { recordingId } = useParams<{ recordingId?: string }>();
  // const { selectedCompany } = useCompany();
  // const [page, setPage] = useState(1);
  // const [totalItemsCount, setTotalItemsCount] = useState(0);
  // const [eventsError, setEventsError] = useState(false);
  // const navigate = useNavigate();

  return <ApplicationPage pageName='Logs'>{""} </ApplicationPage>;
};

export default LogsPage;
