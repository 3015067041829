import React, { useState, useRef, useEffect } from "react";

// Define types for the tabs
interface Indicator {
  width: number;
  left?: number;
}

interface Tab {
  title: string;
  content?: React.ReactNode;
}

interface TabsProps {
  items: Tab[];
}

const Tabs: React.FC<TabsProps> = ({ items }) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [indicatorStyle, setIndicatorStyle] = useState<Indicator>({
    width: 0,
    left: 0,
  });

  // Use refs to track each tab element
  const tabsRef = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    // Update the indicator position and width based on the selected tab
    if (tabsRef.current[selectedTab]) {
      const tabElement = tabsRef.current[selectedTab]!;
      setIndicatorStyle({
        width: tabElement.offsetWidth,
        left: tabElement.offsetLeft,
      });
    }
  }, [selectedTab]);

  return (
    <div className='flex flex-col h-full shadow-md border border-gray-200 rounded-md bg-white py-1 px-2'>
      <div className='relative flex mb-2 border-b border-zinc-200'>
        {items.map(({ title, content }, index) => (
          <div
            key={title}
            ref={(el) => (tabsRef.current[index] = el)}
            onClick={() => content && setSelectedTab(index)}
            className={`py-2 px-3 text-sm font-medium select-none ${
              selectedTab === index
                ? "text-primary-blue-100"
                : "text-neutral-400"
            } ${content ? "cursor-pointer" : "cursor-not-allowed"}`}
          >
            {title}
          </div>
        ))}
        {/* Animated indicator */}
        <div
          className='absolute bottom-0 h-0.5 bg-primary-blue-100 transition-all duration-300'
          style={{
            width: `${indicatorStyle.width}px`,
            left: `${indicatorStyle.left}px`,
          }}
        />
      </div>
      {items[selectedTab].content && (
        <div className='flex flex-1 flex-col overflow-auto'>
          {items[selectedTab].content}
        </div>
      )}
    </div>
  );
};

export default Tabs;
