import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useGetLogsForRecordingLazyQuery } from "../__generated__/graphql";
import { useCompany } from "../contexts/CompanyContext";
import ExpandableAndSelectableLists from "./ExpandableAndSelectableLists";
import Tabs from "./Tabs";

interface RecordingSummaryProps {
  recordingId?: string;
}

const RecordingSummary: React.FC<RecordingSummaryProps> = ({ recordingId }) => {
  const [logs, setLogs] = useState<any[]>([]); // State for logs
  const { selectedCompany } = useCompany();

  const [fetchLogs, { loading: loadingLogs, error: logsError }] =
    useGetLogsForRecordingLazyQuery({
      onCompleted: (data) => {
        setLogs(data.getLogsForRecording);
      },
    });

  useEffect(() => {
    if (recordingId) {
      fetchLogs({
        variables: {
          sessionId: recordingId,
          ...(selectedCompany && {
            companyId: parseInt(selectedCompany.value, 10),
          }),
        },
      });
    }
  }, [recordingId, fetchLogs, selectedCompany]);

  // Templates for ExpandableAndSelectableLists
  const headerTemplate = (log: any) => {
    const logLevelLowercase = log.level.toLowerCase();
    const logDisplayString =
      logLevelLowercase === "log"
        ? "Info"
        : log.level.charAt(0).toUpperCase() + log.level.slice(1);

    return (
      <div className='flex flex-col'>
        {/* Outer container to align items vertically */}
        <div className='flex items-start'>
          {/* Aligns log level and payload */}
          <span
            className={`min-w-[70px] ${
              logLevelLowercase === "error"
                ? "text-red-500"
                : logLevelLowercase === "warning"
                ? "text-yellow-500"
                : "text-slate-500"
            } text-sm font-bold`}
          >
            {logDisplayString}
          </span>
          <div className='ml-2 flex flex-col'>
            {/* Aligns payload and timestamp */}
            <span className='text-slate-800 text-xs font-bold'>
              {log.payload}
            </span>
            <span className='text-slate-500 text-xs'>
              {log.formattedTimestamp}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const gutterTemplate = (log: any) => (
    <div>
      <div className='flex flex-col text-xs text-slate-700'>
        <strong className=''>Trace:</strong>
        {log.trace.map((traceLine: string, index: number) => (
          <div key={index} className='text-xs pl-4'>
            {traceLine}
          </div>
        ))}
      </div>
      <div className='flex items-center gap-1'>
        <strong className='text-xs'>Timestamp:</strong>
        <div className='text-xs'>{log.timestamp}</div>
      </div>
    </div>
  );

  const logsContent = (
    <div className='flex-grow overflow-auto h-full'>
      <ExpandableAndSelectableLists
        items={logs}
        loading={loadingLogs}
        error={logsError}
        headerTemplate={headerTemplate}
        gutterTemplate={gutterTemplate}
        showTimelineBarForRows={false}
      />
    </div>
  );

  const tabs = [
    {
      title: "Logs",
      content: logs.length ? (
        logsContent
      ) : (
        <div className='flex flex-col text-center h-full items-center justify-center text-sm font-medium'>
          <div>😬 It looks like there are no logs to display yet.</div>
          <div className='flex'>
            Check back later or
            <NavLink
              to='https://docs.sailfishqa.com/'
              className='text-primary-blue-100 px-1'
              target='_blank'
            >
              click here
            </NavLink>
            to learn more.
          </div>
        </div>
      ),
    },
    {
      title: "Exceptions",
      content: (
        <div className='flex flex-col text-center h-full items-center justify-center text-sm font-medium'>
          <div>😬 It looks like there are no exceptions to display yet.</div>
          <div className='flex'>
            Check back later or
            <NavLink
              to='https://docs.sailfishqa.com/'
              className='text-primary-blue-100 px-1'
              target='_blank'
            >
              click here
            </NavLink>
            to learn more.
          </div>
        </div>
      ),
    },
    {
      title: "AI Summary",
      content: (
        <div className='flex flex-col text-center h-full items-center justify-center text-sm font-medium'>
          <div>🥳 Big news! AI Summary is on its way, stay tuned!</div>
          <NavLink
            to='https://docs.sailfishqa.com/'
            className='text-primary-blue-100'
            target='_blank'
          >
            Learn more
          </NavLink>
        </div>
      ),
    },
  ];

  return (
    <div className='h-80 my-4'>
      <Tabs items={tabs} />
    </div>
  );
};

export default RecordingSummary;
