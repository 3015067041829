import classNames from "classnames";
import React from "react";

interface FieldProps {
  label: string;
  type: string;
  placeholderText: string;
  defaultValue?: string;
  width?: number; // Width as a percentage
  additionalClassNames?: string;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void; // Add onChange handler
}

interface ButtonWithWidth {
  button: React.ReactNode;
  width?: number; // Width as a percentage
}

interface CtaModalProps {
  title: string;
  fields: FieldProps[];
  additionalFields?: JSX.Element[];
  buttons: ButtonWithWidth[];
  closeModal: () => void; // Add closeModal prop
}

const CtaModal: React.FC<CtaModalProps> = ({
  title,
  fields,
  additionalFields,
  buttons,
  closeModal,
}) => {
  // Destructure closeModal from props
  const renderFields = () => {
    let runningWidthSum = 0;
    const fieldElements: JSX.Element[] = [];

    fields.forEach((field, index) => {
      const fieldWidth = field.width || 100;
      runningWidthSum += fieldWidth;

      const fieldElement = (
        <div
          key={index}
          className={classNames(
            fieldWidth === 100 ? "w-full" : `w-${fieldWidth}`,
            "inline-block",
            field.additionalClassNames,
          )}
        >
          <label className='block text-sm font-medium mb-1'>
            {field.label}
          </label>
          {field.type === "input" ? (
            <input
              type='text'
              placeholder={field.placeholderText}
              defaultValue={field.defaultValue}
              onChange={field.onChange} // Use the onChange handler
              className='w-full border border-gray-300 p-2 rounded focus:ring focus:ring-blue-500'
            />
          ) : field.type === "textarea" ? (
            <textarea
              placeholder={field.placeholderText}
              defaultValue={field.defaultValue}
              onChange={field.onChange} // Use the onChange handler
              className='w-full border border-gray-300 p-2 rounded focus:ring focus:ring-blue-500'
            ></textarea>
          ) : null}
        </div>
      );

      if (runningWidthSum <= 100) {
        fieldElements.push(fieldElement);
      } else {
        runningWidthSum = fieldWidth; // Reset running sum
        fieldElements.push(
          <div key={`break-${index}`} className='w-full'></div>, // Line break
          fieldElement,
        );
      }
    });

    if (additionalFields) {
      fieldElements.push(...additionalFields);
    }

    return fieldElements;
  };

  const renderButtons = () => {
    let runningWidthSum = 0;
    const buttonElements: JSX.Element[] = [];

    buttons.forEach((buttonWithWidth, index) => {
      const buttonWidth = buttonWithWidth.width || 100;
      runningWidthSum += buttonWidth;

      const buttonElement = (
        <div
          key={index}
          className={classNames("inline-block p-2")}
          style={{ width: `${buttonWidth}%` }}
        >
          {buttonWithWidth.button}
        </div>
      );

      if (runningWidthSum <= 100) {
        buttonElements.push(buttonElement);
      } else {
        runningWidthSum = buttonWidth; // Reset running sum
        buttonElements.push(
          <div key={`break-${index}`} className='w-full'></div>, // Line break
          buttonElement,
        );
      }
    });

    return buttonElements;
  };

  return (
    <div className='fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50 flex justify-center items-center'>
      <div className='relative bg-white rounded-lg shadow-lg max-w-lg w-full mx-4'>
        <div className='flex justify-between items-center p-4 md:p-5 border-b rounded-t border-gray-200'>
          <h3 className='text-xl font-semibold text-gray-900'>{title}</h3>
          <button
            type='button'
            className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center focus:outline-none'
            onClick={closeModal} // Use closeModal prop
          >
            <svg
              className='w-3 h-3'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 14 14'
            >
              <path
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6'
              />
            </svg>
            <span className='sr-only'>Close modal</span>
          </button>
        </div>
        <div className='p-4 md:p-5'>
          <div className='space-y-4'>{renderFields()}</div>
          <div className='mt-4 flex flex-wrap'>{renderButtons()}</div>
        </div>
      </div>
    </div>
  );
};

export default CtaModal;
