import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import WebSocketService from "./utils/websocket";

const NavigationListener = () => {
  const location = useLocation();

  useEffect(() => {
    WebSocketService.sendMessage(
      JSON.stringify({
        type: "routeChange",
        url: location.pathname,
      }),
    );
  }, [location]);

  return null;
};

export default NavigationListener;
