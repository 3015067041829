export const APP_ROOT_DOMAIN = `${window.location.protocol}//${window.location.host}`;

export const LINEAR_PRIORITIES = [
  { value: 0, name: "No Priority" },
  { value: 1, name: "Urgent" },
  { value: 2, name: "High" },
  { value: 3, name: "Medium" },
  { value: 4, name: "Low" },
];

// TODO fetch from backend and integration
export const LINEAR_STATUSES = [
  { value: 1, name: "Backlog" },
  { value: 2, name: "Todo" },
  { value: 3, name: "In Progress" },
  { value: 4, name: "In Review" },
  { value: 5, name: "Done" },
  { value: 6, name: "Canceled" },
  { value: 7, name: "Duplicate" },
];

const LINEAR_REDIRECT_URI = `${APP_ROOT_DOMAIN}/settings/integrations/linear`;

export const INTEGRATIONS = {
  LINEAR: "Linear",
  LINEAR_REDIRECT_URI,
  LINEAR_OAUTH_URI: `https://linear.app/oauth/authorize?response_type=code&redirect_uri=${LINEAR_REDIRECT_URI}&state=SECURE_RANDOM&scope=read,write,admin`,
};
