import { CookieAttributes } from "js-cookie";

const IS_PRODUCTION = process.env.NODE_ENV === "production";

export const isTokenExpired = (token?: string | null): boolean => {
  if (!token) return true;

  const payload = JSON.parse(atob(token.split(".")[1]));
  const exp = payload.exp * 1000;
  return Date.now() > exp;
};

export const COOKIE_DOMAIN_OPTIONS: CookieAttributes = {
  secure: IS_PRODUCTION,
  sameSite: IS_PRODUCTION ? "None" : "Lax",
  domain: IS_PRODUCTION ? ".sailfishqa.com" : undefined,
};
