import React from "react";
import { NavLink } from "react-router-dom";

interface IntegrationButtonProps {
  logo: string;
  name: string;
  to: string;
}

const IntegrationButton: React.FC<IntegrationButtonProps> = ({
  logo,
  name,
  to,
}) => {
  return (
    <NavLink to={to} className='link'>
      <button className='flex items-center bg-white text-black px-4 py-2 shadow rounded-md gap-1 text-sm font-medium'>
        <img src={logo} width={32} alt={`${name} Logo`} />
        Integrate with {name}
      </button>
    </NavLink>
  );
};

export default IntegrationButton;
